import { parse, serialize, type CookieSerializeOptions } from 'cookie';

export const getCookieObject = () => {
  return parse(document.cookie);
};

export const setCookie = (
  key: string,
  value: string | null,
  options?: CookieSerializeOptions
) => {
  document.cookie = serialize(key, value ?? '', options);
  return getCookieObject();
};
