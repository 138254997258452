import { Grid } from '@mui/material';
import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { checkRequired } from '@/helpers/defect-form';
import { Defect } from '@/types/interfaces/defect-details';
import { FormSelectOption } from '@/types/interfaces/form';
import FormAutocomplete from '../../Common/Form/FormAutocomplete';
import FormTextField from '../../Common/Form/FormTextField';
import { DynamicFormValues } from '../DefectDetails';

interface MaterialFormProps {
  control: Control<DynamicFormValues>;
  selectOptions: Record<keyof Defect, FormSelectOption[]>;
  disabled: boolean;
  isRequired?: boolean;
}

const MIN_VALUE = 1;
const MAX_VALUE = 100;

const MaterialForm = ({
  control,
  selectOptions,
  disabled,
  isRequired,
}: MaterialFormProps) => {
  const { t } = useTranslation();

  const { field: material1Per } = useController({
    control,
    name: 'material1Percent',
  });

  const { field: material2Per } = useController({
    control,
    name: 'material2Percent',
  });

  const required = isRequired ? t('QC_V2.DEFECT.REQUIRED') : undefined;

  const material1Required = material1Per.value
    ? t('QC_V2.DEFECT.REQUIRED')
    : undefined;

  const material2Required = material2Per.value
    ? t('QC_V2.DEFECT.REQUIRED')
    : undefined;

  const validateMaxValue = (
    value: string | undefined,
    formValue: DynamicFormValues
  ) => {
    if (!value) return;

    const materialPct1 = formValue.material1Percent
      ? parseFloat(formValue.material1Percent)
      : 0;

    const materialPct2 = parseFloat(value);

    const total = materialPct2 + materialPct1;

    if (formValue.material1Percent && total > MAX_VALUE) {
      return t('QC_V2.DEFECT.MATERIAL_PCT_MAX_VAL');
    }

    return materialPct2 > MAX_VALUE
      ? t('QC_V2.DEFECT.MAX', { value: MAX_VALUE })
      : undefined;
  };

  const isHidden = !selectOptions.materials.length;

  if (isHidden) return null;

  return (
    <Grid container spacing={2.5} item>
      <Grid item xs={3}>
        <FormAutocomplete
          data-testid="material1_combobox"
          control={control}
          name="material1"
          label={t('QC_V2.DEFECT.MATERIAL_1')}
          rules={{
            required: checkRequired(
              required || material1Required,
              selectOptions.materials
            ),
          }}
          autocompleteProps={{
            options: selectOptions.materials,
            disabled: disabled,
            isOptionEqualToValue: (option, value) =>
              option.value === value.value,
          }}
        />
      </Grid>

      <Grid item xs={3}>
        <FormTextField
          data-testid="material1_percentage_combobox"
          control={control}
          name="material1Percent"
          label={t('QC_V2.DEFECT.MATERIAL_1_PER')}
          rules={{
            min: {
              value: MIN_VALUE,
              message: t('QC_V2.DEFECT.MIN', { value: MIN_VALUE }),
            },
            max: {
              value: MAX_VALUE,
              message: t('QC_V2.DEFECT.MAX', { value: MAX_VALUE }),
            },
          }}
          textFieldProps={{
            type: 'number',
            disabled: disabled,
            onWheel: (e) => (e.target as HTMLElement)?.blur(),
          }}
        />
      </Grid>

      <Grid item xs={3}>
        <FormAutocomplete
          data-testid="material2_combobox"
          control={control}
          name="material2"
          label={t('QC_V2.DEFECT.MATERIAL_2')}
          rules={{
            required: checkRequired(material2Required, selectOptions.materials),
          }}
          autocompleteProps={{
            options: selectOptions.materials,
            disabled: disabled,
            isOptionEqualToValue: (option, value) =>
              option.value === value.value,
          }}
        />
      </Grid>

      <Grid item xs={3}>
        <FormTextField
          data-testid="material2_percentage_combobox"
          control={control}
          name="material2Percent"
          label={t('QC_V2.DEFECT.MATERIAL_2_PER')}
          rules={{
            min: {
              value: MIN_VALUE,
              message: t('QC_V2.DEFECT.MIN', { value: MIN_VALUE }),
            },
            validate: validateMaxValue,
          }}
          textFieldProps={{
            type: 'number',
            disabled: disabled,
            onWheel: (e) => (e.target as HTMLElement)?.blur(),
          }}
        />
      </Grid>
    </Grid>
  );
};

export default MaterialForm;
