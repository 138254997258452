import { Paper, Typography, Box, Link } from '@mui/material';
import { environments } from '@/environments';
import LoginContainer from '../LoginContainer/LoginContainer';

export interface ErrorProps {
  code?: number;
}

export function Error({ code = 500 }: ErrorProps) {
  let message: JSX.Element;

  switch (code) {
    case 403:
    case 401:
      message = (
        <>
          <Typography variant="h6" paragraph={true}>
            Access denied!
          </Typography>

          <Typography variant="body2">
            Your session has expired or you are not authorized to access this
            page.
          </Typography>
        </>
      );
      break;
    case 404:
      message = (
        <>
          <Typography variant="h6" paragraph={true}>
            Page not found!
          </Typography>

          <Typography variant="body2">
            Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve
            mistyped the URL? Be sure to check your spelling.
          </Typography>
        </>
      );
      break;
    default:
      message = (
        <>
          <LoginContainer />
          <Box p={5}>
            <Typography variant="h4" paragraph={true}>
              Something went wrong!
            </Typography>
            <Typography>
              An unknown error occurred while loading this page.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                mt: 2,
              }}
            >
              Click{' '}
              <Link
                href={environments.siteUrl}
                sx={{
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                }}
              >
                HERE
              </Link>{' '}
              to reload this application.
            </Typography>
          </Box>
        </>
      );
  }

  return (
    <Paper
      sx={{
        textAlign: 'center',
        pb: 5,
      }}
    >
      {message}
    </Paper>
  );
}
