import { Box, Stack, Tooltip, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

const LENGTH = 40;

const Item = ({
  item,
  onCheckItem,
  checked,
}: {
  item: {
    name: string;
  };
  onCheckItem: (checked: boolean, item: any) => void;
  checked: boolean;
}) => {
  const handleCheckItem = () => {
    onCheckItem(!checked, item);
  };

  return (
    <Box
      sx={{
        padding: '0 1rem',
        borderBottom: (theme) => `1px solid ${theme.palette.grey[200]}`,
      }}
    >
      <Stack flexDirection="row" alignItems="center">
        <Checkbox checked={checked} onChange={handleCheckItem} />
        <Tooltip title={item.name} placement="right">
          <Typography variant="body2">
            {item.name.length > LENGTH
              ? `${item.name.substring(0, LENGTH)}...`
              : item.name}
          </Typography>
        </Tooltip>
      </Stack>
    </Box>
  );
};

export default Item;
