import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import { FieldError } from 'react-hook-form';

const ErrorMessage = ({ error }: { error?: FieldError }) => {
  if (!error) return null;
  return (
    <Container>
      <Typography variant="body2" color={(theme) => theme.palette.error.main}>
        {error.message}
      </Typography>
    </Container>
  );
};

const Container = styled('div')(() => ({
  scrollMarginTop: '1rem',
}));

ErrorMessage.displayName = 'ErrorMessage';

export default ErrorMessage;
