import { parse } from 'cookie';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { environmentsTable as environments } from '../environments';
import de from '../i18n/de.json';
import en from '../i18n/en.json';
import es from '../i18n/es.json';
import fr from '../i18n/fr.json';
import it from '../i18n/it.json';
import us from '../i18n/us.json';
const Locales = { de, en, es, fr, it, us };

const $cookies = parse(document.cookie);

const resources = Object.keys(Locales).reduce((prev, curr) => {
  // @ts-expect-error: to be fixed
  prev[curr] = { translation: Locales[curr] };
  return prev;
}, {});

i18n.use(initReactI18next).init({
  debug: false,
  fallbackLng: environments.i18n.fallback || 'us',
  keySeparator: '.',
  lng: $cookies[environments.i18n.i18nCookie]
    ? $cookies[environments.i18n.i18nCookie]
    : environments.i18n.locale,
  load: 'languageOnly',
  nsSeparator: false,
  resources,
});

export default i18n;
