import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

interface DefectDetailProps {
  onClick: () => void;
}

const CloseButton = ({ onClick }: DefectDetailProps) => {
  return (
    <IconButton
      aria-label="close"
      onClick={onClick}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: (theme) => theme.palette.grey[500],
      }}
    >
      <CloseIcon />
    </IconButton>
  );
};

export default CloseButton;
