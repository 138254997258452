import TextField from '@mui/material/TextField';
import { useState, useImperativeHandle, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

const EmailSearch = forwardRef((_props, ref) => {
  const { t } = useTranslation();

  const [value, setValue] = useState<string>('');

  useImperativeHandle(ref, () => ({
    getValue: () => {
      return { email: value };
    },
  }));

  return (
    <TextField
      fullWidth
      data-testid="searchby_email"
      id="seachBy_agentEmail"
      label={t('QC_V2.QC_LST.AGENT_EMAIL')}
      placeholder={t('QC_V2.QC_LST.AGENT_EMAIL')}
      variant="outlined"
      InputLabelProps={{
        shrink: true,
      }}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
});

EmailSearch.displayName = 'EmailSearch';

export default EmailSearch;
