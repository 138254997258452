import { IHydraBase } from './interfaces/hydra';

export enum WAREHOUSE_UNIT_TYPE {
  FR = 'FR',
  US = 'US',
}

export interface WarehouseType extends IHydraBase {
  legacyId: number;
  name: string;
  measurementSystem: WAREHOUSE_UNIT_TYPE;
}
