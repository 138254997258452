import defaultAxios, { AxiosRequestConfig } from 'axios';

import { environments, environmentsTable } from '@/environments';
import { getCookieObject } from '@/helpers/cookie';

const cookie = getCookieObject();

function getAuthorization() {
  const { access_token } = cookie;
  return {
    Authorization: `Bearer ${access_token}`,
  };
}

export function getLang() {
  const lang = cookie[environmentsTable.i18n.i18nCookie];

  return lang || environmentsTable.i18n.locale;
}

const apiClient = defaultAxios.create({
  timeout: 30000,
  baseURL: environments.apiBaseUrl,
  headers: {
    'Accept-Language': `${getLang()};q=0.9,${environmentsTable.i18n.fallback};q=0.8,*;q=0.5`,
    'Content-Type': 'application/json',
    ...getAuthorization(),
  },
});

// Add a response interceptor
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // User is not login
    if ([401, 403].includes(error?.response?.status as number)) {
      window.location.reload();
    }

    if (
      error?.response?.data &&
      (error as any)?.response?.data['hydra:description']
    ) {
      console.error(
        `[QC API] Error on ${error.config?.method?.toUpperCase()} url:'${error.config?.url}'`,
        error.response
      );

      if (
        error.config?.url?.includes('/quality_control') &&
        error.response.status >= 400
      ) {
        return Promise.reject(error);
      }
      return Promise.reject(error);
    }

    // Aborded request silently failed
    if (defaultAxios.isCancel(error)) {
      // Handle the abort request silently
      return Promise.reject(false);
      // You can also return a resolved promise here if you want to suppress the error completely
      // return Promise.resolve();
    }
    return Promise.reject(error);
  }
);

const buildUrlWithVersion = (path: string, version?: string): string => {
  // return quality contorl api path
  return `/quality-control/${version || 'v2'}${path}`;
  // return `/${version || 'v2'}/quality-control${path}`;
};

const fetchData = async (path: string, version?: string, params?: object) => {
  const url = buildUrlWithVersion(path, version);
  const response = await apiClient.get(url, { params });

  return response;
};

const updateData = async (path: string, version?: string, params?: object) => {
  const url = buildUrlWithVersion(path, version);
  const response = await apiClient.put(url, params);

  return response;
};

const postData = async (
  path: string,
  version?: string,
  params?: object,
  config?: AxiosRequestConfig<any>
) => {
  const url = buildUrlWithVersion(path, version);
  const response = await apiClient.post(url, params, config);
  return response;
};

export { apiClient as axios, fetchData, updateData, postData };
