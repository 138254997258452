import { useQuery } from '@tanstack/react-query';
import { getLang } from '@/configs/axios';
import { GET_PRODUCT_COMMENT } from '@/helpers/query-keys';
import { translateProductComment } from '@/services/atlas.service';
import { getProductComments } from '@/services/qc.service';
import { TranslateProductCommentType } from '@/types/ProductInfo';

export const useProductComments = (uuid: string, productId: number) => {
  return useQuery({
    queryKey: [GET_PRODUCT_COMMENT, uuid],
    queryFn: async () => {
      const data = await getProductComments(uuid);
      const agentLang = getLang();
      const translatedComments: TranslateProductCommentType[] =
        await Promise.all(
          data.map(async (comment: any) => {
            if (comment.sourceLang === agentLang) {
              return { ...comment, translation: comment.description };
            }

            const { data: translatedText } = await translateProductComment(
              productId,
              comment.id
            );
            return { ...comment, translation: translatedText.translation };
          })
        );

      return translatedComments;
    },
    enabled: !!uuid,
  });
};
