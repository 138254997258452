import { Typography } from '@mui/material';

export default function KoModalContent() {
  return (
    <>
      <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
        The item is
        <Typography
          component="span"
          sx={{ color: '#F44336', paddingLeft: '0.4rem', fontWeight: '700' }}
        >
          Not Compliant
        </Typography>
        !
      </Typography>
      <Typography variant="body1">It will sort to</Typography>
      <Typography variant="h3">SEND-OUT</Typography>
    </>
  );
}
