import { Stack, Typography } from '@mui/material';

const InfoItem = ({
  label,
  value,
  alignCenter,
}: {
  label: string;
  value: string | number;
  alignCenter?: boolean;
}) => (
  <Stack alignItems={alignCenter ? 'center' : 'unset'}>
    <Typography variant="body2">{label}</Typography>
    <Typography variant="body2" fontWeight="bold">
      {value}
    </Typography>
  </Stack>
);

export default InfoItem;
