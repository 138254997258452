import { Autocomplete, AutocompleteProps, TextField } from '@mui/material';
import { Stack } from '@mui/system';
import {
  Controller,
  FieldPath,
  FieldValues,
  useController,
} from 'react-hook-form';
import { FormField } from '@/types/interfaces/form';
import ErrorMessage from './ErrorMessage';

interface FormAutocompleteProps<
  T,
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> extends FormField<TFieldValues, TName> {
  autocompleteProps?: Partial<
    AutocompleteProps<T, undefined, undefined, undefined>
  >;
}

const FormAutocomplete = <
  T,
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  label,
  name,
  control,
  rules,
  autocompleteProps,
}: FormAutocompleteProps<T, TFieldValues, TName>) => {
  const {
    fieldState: { error },
  } = useController({ control, name });

  const { options = [] } = autocompleteProps || {};

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value, ref, ...field } }) => {
        return (
          <Stack>
            <Autocomplete
              disablePortal
              options={options}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={rules?.required ? `${label} *` : label}
                  inputRef={ref}
                />
              )}
              onChange={(_, data) => onChange(data)}
              value={value}
              {...field}
              {...autocompleteProps}
              sx={(theme) => ({
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: error ? theme.palette.error.main : 'inherit',
                },
              })}
            />
            <ErrorMessage error={error} />
          </Stack>
        );
      }}
    />
  );
};

export default FormAutocomplete;
