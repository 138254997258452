import { useModal } from '@/context/Modal.context';
import { MODAL_TYPES } from '@/context/Qualitycontrol.context';

import AdditionalItemsModal from './AdditionalItemsModal';
import ImageGallary from './ImageGallary';
import DefectModal from '../DefectDetailModal';
import QcSubmitModal from '../QcSubmitModals/QcSubmitModal';

export type QualityControlModalTypes = keyof typeof MODAL_TYPES;

// can consider using lazy loading for modal components
const getModalContent = (modalType: QualityControlModalTypes) => {
  switch (modalType) {
    case MODAL_TYPES.DEFECT_CREATION:
      return <DefectModal />;
    case MODAL_TYPES.DEFECT_VIEW:
      return <DefectModal />;
    case MODAL_TYPES.IMAGE_GALLERY:
      return <ImageGallary />;
    case MODAL_TYPES.QC_SUBMIT:
      return <QcSubmitModal />;
    case MODAL_TYPES.ADDITIONAL_ITEMS:
      return <AdditionalItemsModal />;
  }

  return <div>sth goes wrong</div>;
};

export default function QcPanelModal() {
  const {
    modal: { modalType },
  } = useModal();

  if (modalType) {
    return getModalContent(modalType as QualityControlModalTypes);
  }

  return null;
}
