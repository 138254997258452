import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { IconButton, Stack } from '@mui/material';

interface SectionTitleProps {
  title: string;
  onClick?: () => void;
}

const SectionTitle = ({ title, onClick }: SectionTitleProps) => {
  return (
    <Stack flexDirection="row" alignItems="center" gap={0.5}>
      {title}
      {onClick && (
        <IconButton onClick={onClick}>
          <OpenInNewIcon fontSize="small" />
        </IconButton>
      )}
    </Stack>
  );
};

export default SectionTitle;
