import { MouseEvent } from 'react';
import { environments } from '@/environments';
import { PHOTO_TYPE } from '@/types/interfaces/defect-details';

export function buildImageUrl(
  path: string,
  type?: PHOTO_TYPE,
  width?: number,
  height?: number
): string {
  return path === ''
    ? ''
    : `${environments.imagesBaseUrl}/${environments.imageOptionsPrefix}/w=${width || 1000}${
        height ? `,h=${height}` : ''
      },q=75,f=auto,/${type || PHOTO_TYPE.PRODUIT}/${path}`;
}

export const generateImgId = (type: PHOTO_TYPE, index: number) =>
  `${type}-${index}`;

export const getSVGClickCoords = (e: MouseEvent<SVGSVGElement>) => {
  const target = e.target as SVGSVGElement;

  const point = target.createSVGPoint();

  // pass event coordinates
  point.x = e.clientX;
  point.y = e.clientY;

  // transform to SVG coordinates
  const svgP = point.matrixTransform(target.getScreenCTM()?.inverse());

  return [svgP.x, svgP.y];
};

export const buildFileUrl = (fileName: string) => {
  return `${environments.imagesBaseUrl}/invoices/${fileName}`;
};

export const getPhotoInfo = (path: string) => {
  const [imgType, imgName] = path.split('/');
  return { imgType: imgType as PHOTO_TYPE, imgName };
};
