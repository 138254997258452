import {
  Stack,
  Button as MuiButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/system';
import { RefObject, useEffect, useRef, useState } from 'react';
import { CAMERA_DEVICE_ID } from '@/helpers/local-storage';

interface TakingPictureProps {
  onUpload: (blob?: Blob) => void;
  setStream: (stream: MediaStream) => void;
  videoRef: RefObject<HTMLVideoElement>;
  openTakePhoto: boolean;
  disabled?: boolean;
}

const TakingPicture = ({
  videoRef,
  openTakePhoto,
  onUpload,
  setStream,
  disabled,
}: TakingPictureProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [videoInputs, setVideoInputs] = useState<MediaDeviceInfo[]>([]);
  const [videoInputId, setVideoInputId] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const mediaDeviceStartUp = () => {
    navigator.mediaDevices
      .getUserMedia({
        video: { deviceId: { exact: videoInputId } },
        audio: false,
      })
      .then((stream) => {
        if (videoRef.current) {
          setStream(stream);
          videoRef.current.srcObject = stream;
          videoRef.current.play();
        }
      })
      .catch((err) => {
        console.error(`An error occurred: ${err}`);
      });
  };

  const initiateMediaDevices = async () => {
    if (!navigator.mediaDevices?.enumerateDevices) {
      console.log('enumerateDevices() not supported.');
    } else {
      setLoading(true);

      // DEBUG
      const test = await navigator.mediaDevices.enumerateDevices();
      console.log('test', test);

      // Asking permission
      await navigator.mediaDevices.getUserMedia({ video: true });
      // List cameras
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          console.log('devices', devices);

          const videoDevices = devices.filter(
            (d) => !!d.deviceId && d.kind === 'videoinput'
          );

          console.log('videoDevices', videoDevices);

          setVideoInputs(videoDevices);

          const selectedId = localStorage.getItem(CAMERA_DEVICE_ID) || '';

          const hasSelectedCamera = devices.find(
            (d) => d.deviceId === selectedId
          );

          if (videoDevices.length === 1) {
            setVideoInputId(videoDevices[0].deviceId);
          } else if (hasSelectedCamera) {
            setVideoInputId(selectedId);
          }

          setLoading(false);
        })
        .catch((err) => {
          console.error(`${err.name}: ${err.message}`);
        });
    }
  };

  const takePhoto = () => {
    if (disabled) return;

    const context = canvasRef.current?.getContext('2d');

    if (!canvasRef.current || !context || !videoRef.current) return;

    const width = videoRef.current.offsetWidth;
    const height = videoRef.current.offsetHeight;

    canvasRef.current.width = width;
    canvasRef.current.height = height;
    context.drawImage(videoRef.current, 0, 0, width, height);

    canvasRef.current.toBlob((blob) => {
      if (blob) onUpload(blob);
    }, 'image/*');
  };

  const handleChange = (event: SelectChangeEvent) => {
    localStorage.setItem(CAMERA_DEVICE_ID, event.target.value);
    setVideoInputId(event.target.value);
  };

  useEffect(() => {
    if (openTakePhoto) {
      initiateMediaDevices();
    }
  }, [openTakePhoto]);

  useEffect(() => {
    if (
      videoRef.current &&
      canvasRef.current &&
      openTakePhoto &&
      videoInputId
    ) {
      mediaDeviceStartUp();
    }
  }, [videoRef, canvasRef, videoInputId, openTakePhoto]);

  return (
    <>
      {openTakePhoto && (
        <FormControl fullWidth sx={{ mt: 3, maxWidth: 300 }}>
          <InputLabel id="select-available-devices" sx={{ top: -4 }}>
            Available device
          </InputLabel>

          <Stack flexDirection="row" alignItems="center" gap={2}>
            <Select
              fullWidth
              disabled={loading}
              labelId="select-available-devices"
              value={videoInputId}
              label="Available device"
              onChange={handleChange}
              inputProps={{
                sx: {
                  py: 1.5,
                  fontSize: 14,
                },
              }}
            >
              {videoInputs.map((i) => (
                <MenuItem key={i.deviceId} value={i.deviceId}>
                  {i.label}
                </MenuItem>
              ))}
            </Select>
            {loading && <CircularProgress size={24} />}
          </Stack>
        </FormControl>
      )}

      {openTakePhoto && videoInputId && (
        <Stack sx={{ mt: 2 }}>
          <Video ref={videoRef} />
          <MuiButton
            type="button"
            variant="contained"
            onClick={takePhoto}
            disabled={disabled}
          >
            Take photo
          </MuiButton>
          <Canvas ref={canvasRef} />
        </Stack>
      )}
    </>
  );
};

const Video = styled('video')({
  width: '100%',
  height: '100%',
});

const Canvas = styled('canvas')({
  display: 'none',
});

export default TakingPicture;
