import { FormControlLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

import { useTranslation } from 'react-i18next';
import { useQualityControlTool } from '@/context/Qualitycontrol.context';

import AdditionalInfoSection from './AdditionalInfoSection';

export default function RefurbishmentInfo({
  isViewMode,
}: {
  isViewMode: boolean;
}) {
  const { t } = useTranslation();

  const { refurbishment, setRefurbishment } = useQualityControlTool();

  const renderContent = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            disabled={isViewMode}
            data-testid="refurbishment_checkbox"
            checked={refurbishment}
            onChange={(e) => {
              setRefurbishment(e.target.checked);
            }}
          />
        }
        label={t('QC_V2.MAIN.REFURBISHMENT_DONE')}
      />
    );
  };

  return (
    <AdditionalInfoSection
      title={t('QC_V2.MAIN.REFURBISHMENT')}
      content={renderContent()}
    />
  );
}
