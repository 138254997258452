import axios from 'axios';
import { getLang } from '@/configs/axios';
import { environments } from '@/environments';

export async function translateProductComment(
  productId: number,
  commentId: number
): Promise<{
  data: {
    id: string;
    translation: string;
  };
}> {
  const agentLang = getLang();

  const apiRes = await axios.get(
    `${environments.atlasBaseUrl}/products/${productId}/comments/${commentId}/translation?isoCountry=FR&x-siteid=1&x-language=${agentLang}&x-currency=EUR`
  );

  return apiRes.data;
}
