import { useQuery } from '@tanstack/react-query';
import { GET_DIMENSION_TYPES } from '@/helpers/query-keys';
import { getTypeDimension } from '@/services/qc.service';

export const useGetDimensionTypes = (productId: string) => {
  return useQuery({
    queryKey: [GET_DIMENSION_TYPES, productId],
    queryFn: async () => getTypeDimension(productId),
    enabled: !!productId,
    staleTime: Infinity,
  });
};
