import { useQuery } from '@tanstack/react-query';

import { GET_STANDBY_REASONS } from '@/helpers/query-keys';
import { getStandbyReasons } from '@/services/qc.service';

export const useStandbyReasons = () => {
  const {
    isLoading,
    isSuccess,
    data,
    refetch: refetchStandbyReasons,
  } = useQuery({
    queryKey: [GET_STANDBY_REASONS],
    queryFn: async () => {
      const data = await getStandbyReasons();

      return data;
    },
    staleTime: Infinity,
  });

  return {
    standbyReasons: (data || []).filter(
      (item) => item.type === 'quality_control'
    ),
    isFetchStandbyReasonsSuccess: isSuccess,
    isLoadingStandbyReasons: isLoading,
    refetchStandbyReasons,
  };
};
