import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type ViewMoreTextProps = {
  text: string | JSX.Element;
  maxHeight?: number;
  maxHeightUnit?: string;
  textClassName?: string;
  buttonClassName?: string;
  initiallyExpanded?: boolean;
};

const Button = styled('button')(() => ({
  marginTop: '8px',
}));

export default function ViewMoreText({
  text,
  maxHeight = 98,
  maxHeightUnit = 'px',
  textClassName,
  buttonClassName,
  initiallyExpanded = false,
}: ViewMoreTextProps) {
  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(initiallyExpanded);
  const [showButton, setShowButton] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);

  const handleToggleExpand = () => {
    setIsExpanded((prevState) => !prevState);
  };

  useEffect(() => {
    if (textRef.current && textRef.current.scrollHeight > maxHeight) {
      setShowButton(true);
    }
  }, [maxHeight, maxHeightUnit, text]);

  return (
    <div className={textClassName}>
      {typeof text === 'string' ? (
        <Typography
          component="div"
          variant="body2"
          ref={textRef}
          sx={{
            fontWeight: '400',
            overflow: 'hidden',
            wordBreak: 'break-word',
            whiteSpace: 'pre-line',
            fontSize: '1rem',
            ...(isExpanded
              ? {}
              : {
                  maxHeight: `${maxHeight}${maxHeightUnit}`,
                }),
          }}
        >
          {text}
        </Typography>
      ) : (
        <Box
          ref={textRef}
          sx={{
            overflow: 'hidden',
            ...(isExpanded
              ? {}
              : {
                  maxHeight: `${maxHeight}${maxHeightUnit}`,
                }),
          }}
        >
          {text}
        </Box>
      )}

      {showButton && (
        <Button onClick={handleToggleExpand} className={buttonClassName}>
          {isExpanded ? t('QC_V2.MAIN.VIEW_LESS') : t('QC_V2.MAIN.VIEW_MORE')}
        </Button>
      )}
    </div>
  );
}
