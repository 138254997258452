import { useQuery } from '@tanstack/react-query';

import { GET_BUCKETS } from '@/helpers/query-keys';
import { getBuckets } from '@/services/qc.service';

export const useGetBuckets = (checkpointId: string) => {
  return useQuery({
    queryKey: [GET_BUCKETS, checkpointId],
    queryFn: async () => getBuckets(checkpointId),
    enabled: !!checkpointId,
    staleTime: Infinity,
  });
};
