import { useQuery } from '@tanstack/react-query';
import { GET_AGENT_ACTIVITY } from '@/helpers/query-keys';
import { getAgentActivity } from '@/services/qc.service';

export const useAgentActivity = () => {
  return useQuery({
    queryKey: [GET_AGENT_ACTIVITY],
    queryFn: async () => {
      const data = await getAgentActivity();
      return data;
    },
    staleTime: 0,
  });
};
