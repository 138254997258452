import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Stack, Typography } from '@mui/material';
import { formatUtcTime } from '@/utils/time';

interface PublicCommentProps {
  description: string;
  customerName: string;
  createdAt?: string;
  showDot?: boolean;
}

const PublicComment = ({
  description,
  customerName,
  createdAt,
  showDot,
}: PublicCommentProps) => {
  return (
    <Stack
      mr={0.5}
      mb={1}
      sx={(theme) => ({
        '& > p': { color: theme.palette.text.secondary },
      })}
    >
      <Typography
        variant="body1"
        sx={{ display: 'flex', alignItems: 'baseline', gap: 0.5 }}
      >
        {showDot && <FiberManualRecordIcon sx={{ fontSize: 7.5 }} />}
        {description}
      </Typography>

      <Typography
        fontStyle="italic"
        textAlign="right"
        sx={{ fontSize: '14px !important' }}
      >
        {customerName}
      </Typography>

      {!!createdAt && (
        <Typography
          fontStyle="italic"
          textAlign="right"
          sx={{ fontSize: '14px !important' }}
        >
          {formatUtcTime(new Date(createdAt))}
        </Typography>
      )}
    </Stack>
  );
};

export default PublicComment;
