import { styled } from '@mui/system';
import LogoSVG from '@/assets/svg/Logo';
import { environments } from '@/environments';

const LogoIcon = styled(LogoSVG)(() => ({
  width: '2.5rem',
}));

const Logo: React.FC = () => {
  return (
    <a href={environments.loginUrl}>
      <LogoIcon />
    </a>
  );
};

export default Logo;
