import styled from '@emotion/styled';
import { CircularProgress, Stack, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { buildImageUrl } from '@/helpers/image';
import { QC_PRODUCT_STATUS_MAPPING } from '@/helpers/states';
import { QualityControl } from '@/types/interfaces/agent-activity';
import PhotoBox from '../DefectDetailModal/PhotoBox';

const TableHeadCell = styled(TableCell)`
  font-weight: bold;
`;

const TableBodyCell = styled(TableCell)`
  padding: 4px 1rem;
`;

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'rgba(250, 250, 250, 1)',
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const ActivityTable = ({
  qualityControls,
  isLoading,
}: {
  qualityControls: QualityControl[];
  isLoading?: boolean;
}) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => {
    const result = qualityControls.map((qc) => {
      const startedDate = new Date(qc.agentStartedAt);
      const finishedDate = new Date(qc.agentFinishedAt);

      return {
        id: qc.product.id,
        photo: qc.product.images.length
          ? buildImageUrl(qc.product.images[0])
          : '',
        productId: qc.product.id,
        outcome: QC_PRODUCT_STATUS_MAPPING[qc.state],
        started: qc.agentStartedAt
          ? `${startedDate.getHours()}:${startedDate.getMinutes().toString().padStart(2, '0')}`
          : '',
        finished: qc.agentFinishedAt
          ? `${finishedDate.getHours()}:${finishedDate.getMinutes().toString().padStart(2, '0')}`
          : '',
      };
    });

    return result;
  }, [qualityControls]);

  return (
    <Stack my={4}>
      <Table data-testid="agent_activity_table" size="small">
        <TableHead>
          <TableRow>
            <TableHeadCell>{t('QC_V2.QC_LST.PHOTO')}</TableHeadCell>
            <TableHeadCell>{t('QC_V2.QC_LST.PRODUCT_ID')}</TableHeadCell>
            <TableHeadCell>{t('QC_V2.QC_ACT.OUTCOME')}</TableHeadCell>
            <TableHeadCell>{t('QC_V2.QC_ACT.STARTED')}</TableHeadCell>
            <TableHeadCell>{t('QC_V2.QC_ACT.FINISHED')}</TableHeadCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {tableData.map((d) => {
            return (
              <StyledTableRow key={d.id}>
                <TableBodyCell>
                  {d.photo ? (
                    <PhotoBox imgUrl={d.photo} width={40} height={40} />
                  ) : (
                    '-'
                  )}
                </TableBodyCell>

                <TableBodyCell>{d.productId || '-'}</TableBodyCell>

                <TableBodyCell>{d.outcome || '-'}</TableBodyCell>

                <TableBodyCell>{d.started || '-'}</TableBodyCell>

                <TableBodyCell>{d.finished || '-'}</TableBodyCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>

      {!tableData.length && (
        <Stack flexDirection="row" justifyContent="center" mt={3}>
          {isLoading ? (
            <CircularProgress size={32} />
          ) : (
            <Typography>{t('QC_V2.NO_DATA')}</Typography>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default ActivityTable;
