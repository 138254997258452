import { datadogRum } from '@datadog/browser-rum';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ErrorModal from '@/components/Modal/ErrorModal';
import QcModal from '@/components/Modal/Modal';
import { RoutesMapping } from '@/helpers/routing';
import { QC_PRODUCT_STATE } from '@/helpers/states';
import { useProduct } from '@/hooks/use-product';
import useUpdateQcState from '@/hooks/use-update-qc-state';
import { QcInfoType } from '@/types/QcInfo';

export default function TakeOverHoldItem() {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [isChildDialogOpen, setIsChildDialogOpen] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const { mutate: updateQcState } = useUpdateQcState();
  const { qcData, setQcData, count } = useProduct();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (qcData && (qcData as QcInfoType).state === QC_PRODUCT_STATE.HELD) {
      setIsOpen(true);
    }
  }, [count]);

  const takeOverItem = () => {
    const qcInfo = qcData as QcInfoType;
    datadogRum.addAction('Scan product take over hold item', {
      id: qcInfo.product.id,
    });
    setIsSubmitting(true);
    updateQcState(
      {
        uuid: qcInfo.uuid,
        state: 'agent_unhold',
      },
      {
        onSuccess: ({ data }) => {
          setIsSubmitting(false);
          setIsChildDialogOpen(false);
          setErrorMsg(null);

          if (!data) return;

          setQcData(
            Object.assign({
              ...data,
            })
          );

          if (
            data.state === QC_PRODUCT_STATE.BEING_CONTROLLED &&
            data.product.id
          ) {
            datadogRum.addAction(`Go to QC ${data.product.id}`, {
              id: data.product.id,
            });
            navigate(`${RoutesMapping.QC_TOOL}/${data.product.id}`);
          }
        },
        onError: (error) => {
          setIsSubmitting(false);
          setIsChildDialogOpen(true);

          const errorMsg =
            error.response?.data?.['hydra:description'] || t('QC_V2.STH_WRONG');
          setErrorMsg(errorMsg);
        },
      }
    );
  };

  return (
    <>
      <QcModal
        open={isOpen}
        header=""
        content={t('QC_V2.SCAN.TAKEOVER_MODAL_CONTENT')}
        maxWidth="xs"
        id="scan-item-take-over-modal"
        actionButtons={{
          cancel: {
            text: 'No',
            onClick: () => setIsOpen(false),
          },
          confirm: {
            text: 'Yes',
            onClick: takeOverItem,
            isSubmitting,
          },
        }}
      />
      {isChildDialogOpen && (
        <ErrorModal
          actionButton={{
            text: t('QC_V2.MAIN.OK'),
            onClick: () => {
              setIsChildDialogOpen(false);
              setErrorMsg(null);
            },
          }}
          errorContent={errorMsg}
        />
      )}
    </>
  );
}
