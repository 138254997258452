import { WAREHOUSE_UNIT_TYPE } from '@/types/Warehouse';
import { WAREHOUSE_CONFIG } from './local-storage';

export enum LENGTH_FORMAT {
  CM = 'CM',
  IN = 'IN',
}
enum WEIGHT_FORMAT {
  GRAM = 'GRAM',
}

export type UnitConfigType = {
  dateFormat: string;
  lengthFormat: string;
  weightFormat: string;
  type: WAREHOUSE_UNIT_TYPE;
};

export const UNIT_CONFIG: Record<WAREHOUSE_UNIT_TYPE, UnitConfigType> = {
  FR: {
    type: WAREHOUSE_UNIT_TYPE.FR,
    dateFormat: 'DD/MM/YYYY',
    weightFormat: WEIGHT_FORMAT.GRAM,
    lengthFormat: LENGTH_FORMAT.CM,
  },
  US: {
    type: WAREHOUSE_UNIT_TYPE.US,
    dateFormat: 'MM/DD/YYYY',
    weightFormat: WEIGHT_FORMAT.GRAM,
    lengthFormat: LENGTH_FORMAT.IN,
  },
};

export const getWarehouseConfig = (): UnitConfigType | null => {
  const config = localStorage.getItem(WAREHOUSE_CONFIG);
  return config ? JSON.parse(config) : null;
};

export const getWarehouseLocale = (): string => {
  const config = getWarehouseConfig();

  const locale =
    config && config.type === WAREHOUSE_UNIT_TYPE.FR ? 'en-GB' : 'en-US';

  return locale;
};

export const toWeightUnit = (label?: string) => {
  const config = getWarehouseConfig();

  const unit =
    config && config.weightFormat === WEIGHT_FORMAT.GRAM ? 'gr' : 'N/A';

  if (!label) return unit;

  return `${label} (${unit})`;
};

export const toLengthUnit = (label?: string) => {
  const config = getWarehouseConfig();

  const unit =
    config && config.lengthFormat === LENGTH_FORMAT.CM
      ? LENGTH_FORMAT.CM.toLowerCase()
      : LENGTH_FORMAT.IN.toLowerCase();

  if (!label) return unit;

  return `${label} (${unit})`;
};
