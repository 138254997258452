import { Stack } from '@mui/material';
import { RefObject, useMemo, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import ErrorMessage from '@/components/Common/Form/ErrorMessage';
import { useModal } from '@/context/Modal.context';
import { PHOTO_TYPE } from '@/types/interfaces/defect-details';
import DefectMarking from './DefectMarking';
import DefectPhotos from './DefectPhotos';
import PDPPhotoForm from './PDPPhotoForm';
import UploadedPhotoForm from './UploadedPhotoForm';
import { DynamicFormValues } from '../DefectDetails';
import Title from '../Title';

export const BOX_SIZE = 95;

export interface PhotoProps {
  index: number;
  imgName: string;
  onChange?: (checked: boolean) => void;
}

export type SelectedPhotoType = {
  htmlId: string;
  index: number;
  type?: PHOTO_TYPE;
  path: string;
};

interface PhotoFormProps {
  videoRef: RefObject<HTMLVideoElement>;
  turnOffCamera: () => void;
  setStream: (stream: MediaStream) => void;
}

const PhotoForm = ({ videoRef, turnOffCamera, setStream }: PhotoFormProps) => {
  const {
    modal: { modalInfo },
  } = useModal();

  const { viewMode } = (modalInfo || {}) as {
    viewMode: boolean;
  };

  const { control } = useFormContext<DynamicFormValues>();

  const { fieldState: pdpFieldState, field: pdpField } = useController({
    control,
    name: 'pdpPhotos',
  });

  const { field: uploadedField } = useController({
    control,
    name: 'uploadedPhotos',
  });

  const [selectedPhoto, setSelectedPhoto] = useState<SelectedPhotoType>();

  const viewModePhotos = useMemo(() => {
    if (!viewMode || (!pdpField.value && !uploadedField.value)) {
      return undefined;
    }
    const result = [];

    if (pdpField.value) result.push(...pdpField.value);
    if (uploadedField.value) result.push(...uploadedField.value);

    return result;
  }, [viewMode, pdpField, uploadedField]);

  const handleClick = (data?: SelectedPhotoType) => {
    if (!data) setSelectedPhoto(undefined);
    else {
      const { htmlId, index, type, path } = data;
      setSelectedPhoto({
        htmlId,
        index,
        type,
        path,
      });
    }
  };

  const showTitle = viewMode ? !!viewModePhotos?.length : true;

  return (
    <>
      {showTitle && (
        <Stack flexDirection="row" alignItems="center" gap={2}>
          <Title name="Photos" />
          <ErrorMessage error={pdpFieldState.error} />
        </Stack>
      )}

      {!viewMode && (
        <>
          <PDPPhotoForm selectedPhoto={selectedPhoto} onClick={handleClick} />

          <UploadedPhotoForm
            selectedPhoto={selectedPhoto}
            onClick={handleClick}
            videoRef={videoRef}
            turnOffCamera={turnOffCamera}
            setStream={setStream}
          />
        </>
      )}

      {!!viewModePhotos?.length && (
        <DefectPhotos
          selectedPhoto={selectedPhoto}
          onClick={handleClick}
          photos={viewModePhotos}
        />
      )}

      {!!selectedPhoto && (
        <DefectMarking photo={selectedPhoto} disabled={viewMode} />
      )}
    </>
  );
};

export default PhotoForm;
