import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import '@/configs/i18n.ts';
import '@/configs/datadog.ts';

import '@vestiaire/design-system/dist/css/main.min.css';
import '@vestiaire/vc-vulcain-component-library/style.css';
import App from '@/layout/App';

import { Theme } from '../components/Theme';

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </StrictMode>
);
