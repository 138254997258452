// tslint:disable
import currency from 'currency.js';

import { getWarehouseConfig } from '@/helpers/unit';
import { WAREHOUSE_UNIT_TYPE } from '@/types/Warehouse';
import {
  AdditionalItemType,
  AdditionalItemOption,
} from '../types/AdditionalItem';

const DEFAULT_CURRENCY = '€';

const configuration: Record<
  WAREHOUSE_UNIT_TYPE,
  {
    separator: string;
    decimal: string;
    precision: number;
    fromCents: boolean;
    symbol: string;
  }
> = {
  FR: {
    separator: ' ',
    decimal: ',',
    precision: 2,
    fromCents: true,
    symbol: '',
  },
  US: {
    separator: ',',
    decimal: '.',
    precision: 2,
    fromCents: true,
    symbol: '',
  },
};

export function getPriceFormatted(
  priceInCent: number,
  currencySymbol?: string
): string {
  const warehouseType = getWarehouseConfig()?.type;

  const currencyConfig = warehouseType
    ? configuration[warehouseType]
    : configuration.US;

  const digits = currency(priceInCent, currencyConfig).format();

  return `${digits} ${currencySymbol || DEFAULT_CURRENCY}`.trim();
}

/**
 * Formater to have text with new line render
 *
 * @export
 * @param {string} text
 * @returns {{ __html: string }}
 */
export function formatText(text: string): { __html: string } {
  const html = text.replace(/(?:\r\n|\r|\n)/g, '<br />').replace(/\\/g, '');
  return { __html: html };
}

/**
 * Parses query string portion from uri
 *
 * @export
 * @param {queryOrUri} string with max 1 level of nesting
 * @returns {URLSearchParams}
 */
export function parseQuery(queryOrUri: string): URLSearchParams {
  const pathAndQuery = queryOrUri.split('?');
  return new URLSearchParams(
    pathAndQuery.length === 1 ? pathAndQuery[0] : pathAndQuery[1]
  );
}

/**
 * Return the agent name by parsing is email
 *
 * @export
 * @param {string} email
 * @returns {string}
 */
export function parseEmail(email: string): string {
  const name = email.split('@');
  return name[0].replace('.ext', '');
}

/**
 * Parse an array to lookfor the prop that match a defined value
 *
 * @export
 * @param {*} arr
 * @param {string} prop
 * @param {(string | number)} value
 * @returns {*}
 */
export function findInArray(
  arr: any,
  prop: string,
  value: string | number
): any {
  if (!arr) {
    return;
  }
  return arr.find((obj: any) => obj[prop] === value);
}

/**
 * Extract list of ID's from string
 *
 * @export
 * @param {string} sentence
 * @returns {number[]}
 */
export function extractId(sentence: string): string[] | null {
  return sentence.match(/\b\d+\b/g);
}

export function displaySnackbar(message: string) {
  window.dispatchEvent(
    new CustomEvent('snackbar', {
      detail: {
        message,
      },
    })
  );
}

export function displaySnackbarError(message: string) {
  window.dispatchEvent(
    new CustomEvent('snackbar', {
      detail: {
        message,
        status: 'error',
      },
    })
  );
}

export function displaySnackbarValid(message: string) {
  window.dispatchEvent(
    new CustomEvent('snackbar', {
      detail: {
        message,
        status: 'valid',
      },
    })
  );
}

export function displaySnackbarWarning(message: string) {
  window.dispatchEvent(
    new CustomEvent('snackbar', {
      detail: {
        message,
        status: 'warning',
      },
    })
  );
}

/**
 * Do a simple array compare and remove the most used additional items from the list
 * to happen them at the beginning.
 *
 * @param {IAdditionalItemDto[]} ail : full list
 * @param {IAdditionalItemDto[]} aih : most used list
 * @returns {IAdditionalItemDto[]}
 */
export function orderListOfAdditionalItems(
  ail: AdditionalItemType[],
  aih: AdditionalItemType[]
): AdditionalItemType[] {
  const updatedList = ail.filter((item: AdditionalItemType): boolean => {
    // If we find a match, return false to remove it from the full list.
    return !aih.some((itemTwo: AdditionalItemType): boolean => {
      return item.uuid === itemTwo.uuid;
    });
  });
  return [...aih, ...updatedList];
}

/**
 * Format the additional items to be used on the search select
 *
 * @param {AdditionalItemType[]} addiItems
 * @returns {AdditionalItemOption[]}
 */
export function formatAddiItems(
  addiItems: AdditionalItemType[]
): AdditionalItemOption[] {
  for (const addItem of addiItems) {
    (addItem as AdditionalItemOption).label = addItem.name;
    (addItem as AdditionalItemOption).value = addItem.uuid;
  }
  return addiItems as AdditionalItemOption[];
}

// export function isRejected(
//   input: PromiseSettledResult<any>
// ): input is PromiseRejectedResult { return input.status === 'rejected' }

// export function isFulfilled(
//   input: PromiseSettledResult<any>
// ): input is PromiseFulfilledResult<any> { return input.status === 'fulfilled' }

export function isRejected(input: any): input is any {
  return input.status === 'rejected';
}

export function isFulfilled(input: any): input is any {
  return input.status === 'fulfilled';
}

export function getUserNameFromEmail(email: string) {
  if (!email) {
    return null;
  }
  // Split the email string at the '@' symbol
  const parts = email.split('@');

  // The username is the part before the '@'
  if (parts.length > 1) {
    return parts[0];
  } else {
    return null; // Return null if the email format is invalid
  }
}
