import { IHydra } from '../types/interfaces/hydra';

export function isLastPage(data: IHydra) {
  const currentPage = data['hydra:view']['@id'];
  const lastPage = data['hydra:view']['hydra:last'];
  return !lastPage || currentPage === lastPage;
}

export function getNextPage(data: IHydra) {
  return data['hydra:view']['hydra:next'];
}

export function getMemberData(data: IHydra) {
  return data['hydra:member'];
}

export function getTotalItems(data: IHydra): number {
  return data['hydra:totalItems'];
}
