import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useModal } from '@/context/Modal.context';
import { buildImageUrl } from '@/helpers/image';

export const MAX_VISIBLE_IMAGE_COUNT = 3;

const Image = styled('img')(() => ({
  display: 'block',
  width: '9rem',
  height: '9rem',
  marginRight: '1rem',
  position: 'relative',
  zIndex: 0,
  ':hover': { cursor: 'pointer' },
}));

const ProductImage = ({
  imagePath,
  index,
  count,
}: {
  imagePath: string;
  index: number;
  count?: number;
}) => {
  const { setModalInfo } = useModal();

  const showImageNumber = !!count && count > MAX_VISIBLE_IMAGE_COUNT - 1;

  const handleClick = () => {
    setModalInfo({
      modalType: 'IMAGE_GALLERY',
      modalInfo: {
        initialIndex: index,
      },
    });
  };

  return (
    <Box sx={{ position: 'relative', ':hover': { cursor: 'pointer' } }}>
      <Image
        data-testid="product_info_image"
        src={buildImageUrl(imagePath)}
        key={imagePath}
        alt={imagePath}
        style={{
          filter: showImageNumber ? 'brightness(0.5)' : 'unset',
        }}
        onClick={handleClick}
      />

      {showImageNumber && (
        <Typography
          variant="body1"
          fontWeight="bold"
          color="white"
          onClick={handleClick}
          sx={{
            position: 'absolute',
            zIndex: 10,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >{`+${count - (MAX_VISIBLE_IMAGE_COUNT - 1)}`}</Typography>
      )}
    </Box>
  );
};

export default ProductImage;
