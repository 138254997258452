import { useQuery } from '@tanstack/react-query';
import { GET_CHECK_LIST, GET_ADDITIONAL_ITEMS } from '@/helpers/query-keys';
import { getCheckPoints, getAdditionalItems } from '@/services/qc.service';

export const getCheckPointQueryKey = (uuid: string) => [GET_CHECK_LIST, uuid];

export const useCheckPoints = (productUuid: string) => {
  const { data, refetch: refetchCheckPoints } = useQuery({
    queryKey: getCheckPointQueryKey(productUuid),
    queryFn: async () => {
      const data = await getCheckPoints(productUuid);

      return data;
    },
    enabled: !!productUuid,
    /*
      Stale time would be Infinity cause this data won't be updated often
      We leverage this to manipulate the cache data (used in useSkipCheckpoint hook)
    */
    staleTime: Infinity,
  });

  return {
    checkPointList: data,
    refetchCheckPoints,
  };
};

export const useAdditionalItemList = () => {
  const {
    data,
    isLoading,
    refetch: refetchAdditionalItems,
  } = useQuery({
    queryKey: [GET_ADDITIONAL_ITEMS],
    queryFn: async () => {
      const data = await getAdditionalItems();

      return data;
    },
    staleTime: Infinity,
  });

  return {
    additionalItemList: data,
    refetchAdditionalItems,
    isLoadingAddtionalItems: isLoading,
  };
};
