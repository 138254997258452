import { useTranslation } from 'react-i18next';
import AdditionalItemsList from '@/components/AdditionalItemsList/AdditionalItemsList';
import QcModal from '@/components/Modal/Modal';
import { useModal } from '@/context/Modal.context';
import { useQualityControlTool } from '@/context/Qualitycontrol.context';
import { AdditionalItemType } from '@/types/AdditionalItem';

export default function AdditionalItemsModal() {
  const { t } = useTranslation();

  const { setAdditionalItems } = useQualityControlTool();
  const { setModalInfo, modalData } = useModal();

  const handleConfirmItems = () => {
    if (modalData) setAdditionalItems(modalData as AdditionalItemType[]);
    setModalInfo({
      modalType: '',
      modalInfo: {},
    });
  };

  return (
    <QcModal
      open={true}
      maxWidth="lg"
      id="additional-items-modal"
      header={t('QC_V2.MAIN.ADDITIONAL_ITEMS')}
      content={<AdditionalItemsList />}
      actionButtons={{
        cancel: {
          text: t('QC_V2.MAIN.CANCEL'),
          onClick: () => {
            setModalInfo({
              modalType: '',
              modalInfo: {},
            });
          },
        },
        confirm: {
          onClick: handleConfirmItems,
          text: t('QC_V2.MAIN.CONFIRM'),
        },
      }}
    />
  );
}
