import ArrowUpwardOutlined from '@mui/icons-material/ArrowUpwardOutlined';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Button from './Button';

export interface UploadButtonProps {
  disabled: boolean;
  onUpload?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const UploadButton = ({ onUpload, disabled }: UploadButtonProps) => {
  const { t } = useTranslation();

  return (
    <label htmlFor="upload-photo" style={{ display: 'inline-block' }}>
      <input
        style={{ display: 'none' }}
        id="upload-photo"
        name="upload-photo"
        type="file"
        accept="image/*"
        multiple
        onChange={onUpload}
        disabled={disabled}
      />
      <Button
        name={t('QC_V2.DEFECT.UPLOAD_A_PHOTO')}
        icon={<ArrowUpwardOutlined />}
        props={{ disabled: disabled }}
      />
      {''}
    </label>
  );
};

export default UploadButton;
