import { Grid } from '@mui/material';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toWeightUnit } from '@/helpers/unit';
import FormTextField from '../../Common/Form/FormTextField';
import { DynamicFormValues } from '../DefectDetails';

interface WeightFormProps {
  control: Control<DynamicFormValues>;
  disabled: boolean;
  isRequired?: boolean;
}

const WeightForm = ({ control, disabled, isRequired }: WeightFormProps) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <FormTextField
        control={control}
        name="weight"
        label={toWeightUnit(t('QC_V2.DEFECT.WEIGHT'))}
        rules={{
          required: isRequired ? t('QC_V2.DEFECT.REQUIRED') : undefined,
        }}
        textFieldProps={{
          type: 'number',
          disabled: disabled,
        }}
      />
    </Grid>
  );
};

export default WeightForm;
