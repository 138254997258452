import { Alert, AlertTitle, CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import { useState } from 'react';

import { useScanAlert, AlertMessageType } from '@/hooks/use-scanAlert';

type SnackBarPropsType = {
  open: boolean;
  productId: string;
  alertType: string;
};

export default function SnackBarNotification({ open }: SnackBarPropsType) {
  const [isOpen, setIsOpen] = useState<boolean>(open);
  const { alertMessage, isLoading } = useScanAlert();
  const { icon, color, title, message, testid } =
    alertMessage as AlertMessageType;

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Box>
      <Snackbar
        open={isOpen}
        sx={{ marginTop: '5rem' }}
        autoHideDuration={60000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Alert
          icon={
            isLoading ? <CircularProgress size={18} sx={{ mt: 0.2 }} /> : icon
          }
          // @ts-expect-error: color type needs to be updated
          color={color}
          data-testid={testid}
        >
          <AlertTitle>{title}</AlertTitle>
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
