import { useTranslation } from 'react-i18next';
import QcModal, { ActionButtonType } from './Modal';

export default function ErrorModal({
  errorHeader,
  errorContent,
  actionButton,
}: {
  errorHeader?: string | React.ReactNode;
  errorContent?: string | React.ReactNode;
  actionButton: ActionButtonType;
}) {
  const { t } = useTranslation();

  const { text, onClick } = actionButton;

  return (
    <QcModal
      open={true}
      maxWidth="xs"
      id="error-modal"
      header={errorHeader || 'Sth goes wrong'}
      content={errorContent || 'Your submission fails'}
      actionButtons={{
        confirm: {
          text: text || t('QC_V2.MAIN.OK'),
          onClick: onClick,
        },
      }}
    />
  );
}
