import { useQuery } from '@/hooks/use-url';

export const useQcMode = () => {
  const urlParams = useQuery();
  const mode = urlParams.get('mode');
  const isViewMode = mode === 'view';

  return {
    mode,
    isViewMode,
  };
};
