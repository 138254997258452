import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { Stack, Typography } from '@mui/material';
import { ChangeEvent, RefObject, useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { buildImageUrl, generateImgId, getPhotoInfo } from '@/helpers/image';
import { useUploadImage } from '@/hooks/defect-details/use-upload-image';
import { useProduct } from '@/hooks/use-product';
import { PHOTO_TYPE } from '@/types/interfaces/defect-details';
import { QcInfoType } from '@/types/QcInfo';
import { SelectedPhotoType } from './PhotoForm';
import PhotoItem from './PhotoItem';
import TakingPicture from './TakingPicture';
import Button from '../Button';
import { DynamicFormValues } from '../DefectDetails';
import LoadingBox from '../LoadingBox';
import UploadButton from '../UploadButton';

const SIZE_IN_MB = 10;
const FILE_MAX_SIZE = SIZE_IN_MB * 1024 * 1024; // 10MB

export const MAX_UPLOAD_FILE = 3;

interface UploadedPhotoFormProps {
  videoRef: RefObject<HTMLVideoElement>;
  turnOffCamera: () => void;
  setStream: (stream: MediaStream) => void;
  onClick: (data?: SelectedPhotoType) => void;
  selectedPhoto?: SelectedPhotoType;
}

const UploadedPhotoForm = ({
  selectedPhoto,
  videoRef,
  turnOffCamera,
  setStream,
  onClick,
}: UploadedPhotoFormProps) => {
  const { t } = useTranslation();

  const { qcData } = useProduct();

  const { uuid } = qcData as QcInfoType;

  const { control } = useFormContext<DynamicFormValues>();

  const {
    fields: uploadedPhotos,
    append,
    update,
  } = useFieldArray({
    control,
    name: 'uploadedPhotos',
  });

  const { isPending, mutateAsync } = useUploadImage();

  const [openTakePhoto, setOpenTakePhoto] = useState(false);

  const [error, setError] = useState('');

  const isDisabled = useMemo(() => {
    const shownPhotos = uploadedPhotos.filter((p) => !p.isHidden);
    return shownPhotos.length === MAX_UPLOAD_FILE;
  }, [uploadedPhotos]);

  const handleCheck = (id: number, imgName: string) => (checked: boolean) => {
    const photo = {
      id,
      checked,
      path: `${PHOTO_TYPE.NPV}/${imgName}`,
      type: PHOTO_TYPE.NPV,
    };

    let inputtedField = uploadedPhotos[id];

    if (!inputtedField) {
      update(photo.id, photo);
      return;
    }

    inputtedField = Object.assign(inputtedField, photo);
    update(photo.id, inputtedField);
  };

  const clearPhotoFromView = () => onClick(undefined);

  const handleOpenTakingPhoto = () => {
    const value = !openTakePhoto;

    if (!value) turnOffCamera();
    setOpenTakePhoto(value);

    clearPhotoFromView();
  };

  const handleClick = (data: SelectedPhotoType) => () => {
    if (openTakePhoto) handleOpenTakingPhoto();
    onClick(data);
  };

  const handleRemove = (index: number, isClicked: boolean) => () => {
    if (!uploadedPhotos.length || !uploadedPhotos?.[index]) return;

    const inputtedField = uploadedPhotos[index];
    update(index, { ...inputtedField, id: index, isHidden: true });

    if (!isClicked) return;

    clearPhotoFromView();
  };

  const upload = async (files: File[]) => {
    // TODO: Vuong will check for api to process parallel requests
    // https://vestiairecollective.atlassian.net/browse/OPS-4382
    for (let i = 0; i < files.length; i++) {
      const result = await mutateAsync({ uuid, file: files[i] });
      const id = !uploadedPhotos.length ? i : uploadedPhotos.length + i;
      append({ id, path: result.imageUrl });
    }
  };

  const handleUploadFiles = (e?: ChangeEvent<HTMLInputElement>) => {
    if (isDisabled) return;

    const chosenFiles = e?.target.files;

    setError('');

    if (!chosenFiles) return;

    const uploadingFiles = [...chosenFiles];

    const hasLargeFile = uploadingFiles.some((f) => f.size > FILE_MAX_SIZE);

    if (hasLargeFile) {
      setError(t('QC_V2.DEFECT.MAX_UPLOAD_SIZE', { size: `${SIZE_IN_MB} MB` }));
    }

    const uploaded = uploadedPhotos.filter((p) => !p.isHidden);

    const uploadedLength = MAX_UPLOAD_FILE - uploaded.length;

    if (uploadedLength <= 0) return;

    const files = uploadingFiles
      .slice(0, uploadedLength)
      .filter((f) => f.size <= FILE_MAX_SIZE)
      .map(
        (f) =>
          new File([f], `${Date.now()}-${(f as File).name}`, {
            type: f.type,
          })
      );

    if (!files.length) return;

    upload(files);
  };

  const handleUploadBlob = (blob?: Blob) => {
    if (isDisabled) return;

    const chosenFile: Blob | undefined = blob;

    if (!chosenFile) return;

    const fileName = `${Date.now()}`;

    const file = new File([chosenFile], fileName, {
      type: chosenFile.type,
    });

    upload([file]);
  };

  return (
    <>
      <Typography variant="body1" sx={{ mt: 2 }}>
        {t('QC_V2.DEFECT.UPLOADED_PHOTOS')?.toUpperCase()}
      </Typography>

      <Typography variant="body2" fontStyle="italic" sx={{ mb: 2 }}>
        {`(${t('QC_V2.DEFECT.MAX_UPLOAD_PHOTOS', { number: MAX_UPLOAD_FILE })})`}
      </Typography>

      <Stack sx={{ mt: 2, flexDirection: 'row', gap: 2, flexWrap: 'wrap' }}>
        <UploadButton onUpload={handleUploadFiles} disabled={isDisabled} />

        <Button
          name={t('QC_V2.DEFECT.TAKE_A_PHOTO')}
          icon={<CameraAltOutlinedIcon />}
          isActive={openTakePhoto}
          props={{
            onClick: handleOpenTakingPhoto,
          }}
        />

        {uploadedPhotos.map((field, idx) => {
          if (!field.path || field.isHidden) return null;

          const { imgType, imgName } = getPhotoInfo(field.path);

          const url = buildImageUrl(imgName, imgType as PHOTO_TYPE);

          const htmlId = generateImgId(PHOTO_TYPE.NPV, idx);

          const isClicked = htmlId === selectedPhoto?.htmlId;

          return (
            <PhotoItem
              key={field.id}
              imgUrl={url}
              name={`uploadedPhotos.${idx}`}
              isChecked={field.checked}
              isClicked={isClicked}
              onClick={handleClick({
                htmlId,
                type: PHOTO_TYPE.NPV,
                index: idx,
                path: `${PHOTO_TYPE.NPV}/${imgName}`,
              })}
              onDelete={handleRemove(idx, isClicked)}
              onChange={handleCheck(idx, imgName)}
            />
          );
        })}

        {isPending && <LoadingBox />}
      </Stack>

      {!!error && (
        <Stack my={1}>
          <Typography variant="body2" color="error">
            {error}
          </Typography>
        </Stack>
      )}

      <TakingPicture
        onUpload={handleUploadBlob}
        setStream={setStream}
        videoRef={videoRef}
        openTakePhoto={openTakePhoto}
        disabled={isDisabled}
      />

      {/* {openTakePhoto && <QRCode />} */}
    </>
  );
};

export default UploadedPhotoForm;
