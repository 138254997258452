import { Box, CircularProgress, Grid, Stack } from '@mui/material';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormAutocomplete from '@/components/Common/Form/FormAutocomplete';
import FormTextField from '@/components/Common/Form/FormTextField';
import { useModal } from '@/context/Modal.context';
import { useDynamicDefects } from '@/hooks/defect-details/use-dynamic-defects';
import { useGetBuckets } from '@/hooks/defect-details/use-get-buckets';
import { DefectOptionType } from '@/types/interfaces/defect-details';
import { DynamicFormValues } from '../DefectDetails';
import Title from '../Title';

const BasicInfoForm = () => {
  const { t } = useTranslation();

  const {
    modal: { modalInfo },
  } = useModal();

  const { checkpointId, viewMode } = modalInfo || {};

  const { data: buckets, isLoading: bucketLoading } =
    useGetBuckets(checkpointId);

  const { watch, control } = useFormContext<DynamicFormValues>();

  const selectedDefect = watch('defect');

  const { fields } = useDynamicDefects({
    control,
    viewMode,
    requiredFields: selectedDefect?.requiredFields,
    optionalFields: selectedDefect?.optionalFields,
  });

  const defectOptions: DefectOptionType[] = useMemo(() => {
    if (!buckets?.length) return [];

    const defects = buckets.reduce((accumulator, bucket) => {
      if (bucket.defectTypes.length) {
        const bucketDefects = bucket.defectTypes
          .map((f) => ({
            label: f.name,
            id: f.id,
            bucketId: bucket.id,
            bucketName: bucket.name,
            requiredFields: bucket.requiredFields,
            optionalFields: bucket.optionalFields,
          }))
          .sort((a, b) => -b.label.localeCompare(a.label));

        accumulator.push(...bucketDefects);
      }
      return accumulator;
    }, [] as DefectOptionType[]);

    return defects.sort((a, b) => -b.bucketName.localeCompare(a.bucketName));
  }, [buckets]);

  return (
    <Box>
      <Stack flexDirection="row" gap={1.5} alignItems="center">
        <Title name={t('QC_V2.MAIN.BASIC_INFO')} />
        {bucketLoading && <CircularProgress size={18} />}
      </Stack>

      <Grid container spacing={2.5}>
        <Grid item xs={12}>
          <FormAutocomplete
            data-testid="choose_a_defect_combobox"
            control={control}
            name="defect"
            label={t('QC_V2.DEFECT.CHOOSE_DEFECT')}
            rules={{
              required: t('QC_V2.DEFECT.REQUIRED'),
            }}
            autocompleteProps={{
              options: defectOptions,
              isOptionEqualToValue: (option, value) => {
                return option.id === value.id;
              },
              getOptionKey: (opt) => `${opt.id}-${opt.bucketId}`,
              groupBy: (opt) => `${opt.bucketName}`,
              disabled: viewMode || bucketLoading,
            }}
          />
        </Grid>

        {/* Dynamic fields based on api required fields */}
        {fields}

        <Grid item xs={12}>
          <FormTextField
            data-testid="defect_comment_textfield"
            control={control}
            name="comment"
            label={t('QC_V2.DEFECT.COMMENT')}
            textFieldProps={{
              multiline: true,
              rows: 3,
              disabled: viewMode,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default BasicInfoForm;
