import CloseIcon from '@mui/icons-material/Close';
import { Stack } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Zoom, Pagination, Keyboard } from 'swiper/modules';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/zoom';
import 'swiper/css/pagination';
import { useModal } from '@/context/Modal.context';
import { buildImageUrl } from '@/helpers/image';
import { useProduct } from '@/hooks/use-product';
import { QcInfoType } from '@/types/QcInfo';
import './ImageGallery.scss';
import ZoomButton from './ZoomButton';

export default function ImageGallery() {
  const { t } = useTranslation();

  const { setModalInfo, modal } = useModal();
  const { qcData } = useProduct();
  const qcInfo = qcData as QcInfoType;
  const productImgs = (qcInfo.product?.images || []).map((img) =>
    buildImageUrl(img)
  );

  const ref = useRef<SwiperRef>(null);

  const {
    modalInfo: { initialIndex },
  } = modal;

  return (
    <Dialog
      fullWidth
      fullScreen
      open
      aria-labelledby="product-image-gallery"
      scroll="paper"
      PaperProps={{
        sx: {
          backgroundColor: 'black',
        },
      }}
    >
      <DialogTitle
        id="product-image-gallery"
        sx={{
          color: '#fff',
        }}
      >
        <Stack
          flexDirection="row"
          gap={8}
          width="100%"
          justifyContent="flex-end"
        >
          <Stack flexDirection="row" gap={1}>
            <ZoomButton
              btnName={t('QC_V2.MAIN.ZOOM_IN')}
              onClick={() => {
                if (ref.current) ref.current.swiper.zoom.in();
              }}
            />
            <ZoomButton
              btnName={t('QC_V2.MAIN.ZOOM_OUT')}
              onClick={() => {
                if (ref.current) ref.current.swiper.zoom.out();
              }}
            />
          </Stack>

          <FormControlLabel
            control={<CloseIcon />}
            label={t('QC_V2.MAIN.CLOSE')}
            sx={{ fontSize: 15 }}
            disableTypography
            onClick={() =>
              setModalInfo({
                modalType: '',
                modalInfo: {},
              })
            }
          />
        </Stack>
      </DialogTitle>

      <Swiper
        ref={ref}
        className="mySwiper"
        initialSlide={initialIndex || 0}
        slidesPerView={1}
        direction="vertical"
        pagination={{
          clickable: true,
          renderBullet: (index, className) => {
            return `<img class="${className}" src="${productImgs[index]}" />`;
          },
        }}
        zoom={true}
        keyboard={{
          enabled: true,
          pageUpDown: true,
        }}
        modules={[Zoom, Pagination, Keyboard]}
      >
        {productImgs.map((img, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="swiper-zoom-container">
                <img src={img} alt={img} loading="lazy" />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Dialog>
  );
}
