import { useQuery } from '@tanstack/react-query';
import { useAuthContext } from '@/context/Authentication.context';
import { GET_AGENT_INFO } from '@/helpers/query-keys';
import { getAgent } from '@/services/qc.service';

export const useAgentInfo = () => {
  const { isAuthenticated, user } = useAuthContext();

  const { data } = useQuery({
    queryKey: [GET_AGENT_INFO, user.email],
    queryFn: async () => {
      const data = await getAgent(user.email);
      return data;
    },
    enabled: !!isAuthenticated && !!user.email,
    staleTime: Infinity,
  });

  return {
    agent: data,
  };
};
