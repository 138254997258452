import { datadogRum } from '@datadog/browser-rum';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Breakpoint } from '@mui/material/styles';
import { t } from 'i18next';
import { ReactNode, useEffect } from 'react';

export type ActionButtonType = {
  onClick?: (params?: any) => void;
  text: string;
  isSubmitting?: boolean;
};

export type ActionButtonsType = {
  cancel?: ActionButtonType;
  confirm?: ActionButtonType;
};

export default function QcModal({
  header,
  content,
  maxWidth = 'md',
  id,
  actionButtons,
  dialogProps,
  disableBackdropClick,
  open,
}: {
  header?: string | ReactNode;
  content: string | ReactNode;
  maxWidth?: Breakpoint | false;
  id: string;
  actionButtons?: ActionButtonsType | ReactNode;
  dialogProps?: Partial<DialogProps>;
  disableBackdropClick?: boolean;
  open: boolean;
}) {
  const { cancel: cancelButton, confirm: confirmButton } =
    (actionButtons as ActionButtonsType) || {};

  const handleModalClose = () => {
    const { onClick: onCancel } = cancelButton || {};

    if (typeof onCancel === 'function') {
      onCancel();
    }
  };
  const handleOnConfirm = () => {
    const { onClick: onConfirm } = confirmButton || {};
    if (confirmButton?.isSubmitting) return;

    if (typeof onConfirm === 'function') {
      onConfirm();
    } else {
      handleModalClose();
    }
  };

  const getModalActions = () => {
    if (!cancelButton && !confirmButton) {
      return actionButtons as ReactNode;
    }

    return (
      <>
        {cancelButton && (
          <Button autoFocus onClick={handleModalClose}>
            {cancelButton.text || t('QC_V2.MAIN.CANCEL')}
          </Button>
        )}
        {confirmButton && (
          <Button
            type="submit"
            autoFocus
            onClick={handleOnConfirm}
            disabled={confirmButton.isSubmitting}
          >
            {confirmButton.isSubmitting ? (
              <CircularProgress size={18} color="inherit" />
            ) : (
              confirmButton.text || t('QC_V2.MAIN.CONFIRM')
            )}
          </Button>
        )}
      </>
    );
  };

  useEffect(() => {
    if (open) {
      datadogRum.addAction(`Open modal ${id}`);
    } else {
      datadogRum.addAction(`Close modal ${id}`);
    }
  }, [open]);

  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth || 'md'}
      open={open}
      onClose={(_, reason) => {
        const isBackdropAction =
          reason === 'backdropClick' || reason === 'escapeKeyDown';

        if (disableBackdropClick && isBackdropAction) return;

        handleModalClose();
      }}
      aria-labelledby={id}
      scroll="paper"
      {...dialogProps}
    >
      {header && <DialogTitle id={`${id}-title`}>{header}</DialogTitle>}
      <DialogContent>{content}</DialogContent>
      {!!actionButtons && <DialogActions>{getModalActions()}</DialogActions>}
    </Dialog>
  );
}
