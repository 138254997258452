interface MarkingCircleProps {
  id: string;
  x: number;
  y: number;
  onClick: (id: string) => void;
}

const MarkingCircle = ({ id, x, y, onClick }: MarkingCircleProps) => {
  return (
    <circle
      key={id}
      id={id}
      cx={x}
      cy={y}
      r="6"
      stroke="rgba(251, 140, 0, 1)"
      strokeWidth="1px"
      fill="transparent"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        const target = e.target as HTMLElement;
        if (target.id) onClick(target.id);
      }}
    />
  );
};

export default MarkingCircle;
