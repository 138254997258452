import { Typography } from '@mui/material';

export default function OkModalContent() {
  return (
    <>
      <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
        You approve the item as
        <Typography
          component="span"
          sx={{ color: '#4CAF50', paddingLeft: '0.4rem', fontWeight: '700' }}
        >
          Compliant
        </Typography>
        !
      </Typography>
      <Typography variant="body1">It will sort to</Typography>
      <Typography variant="h3">SEND-OUT</Typography>
    </>
  );
}
