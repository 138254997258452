import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CheckPointType } from '@/types/Checkpoint';
import { getCheckPointQueryKey } from './use-checkpoints';

const useSkipCheckpoint = () => {
  const queryClient = useQueryClient();

  return useMutation({
    onMutate: ({
      uuid,
      id,
      skipped,
    }: {
      uuid: string;
      id: number;
      skipped: boolean;
    }) => {
      const queryKey = getCheckPointQueryKey(uuid);

      const checkpointCache =
        queryClient.getQueryData<CheckPointType[]>(queryKey);

      if (!checkpointCache || !checkpointCache?.length) return;

      const checkpoint = checkpointCache.find((c) => c.id === id);

      if (!checkpoint) return;

      checkpoint.isSkipped = skipped;

      queryClient.setQueryData<CheckPointType[]>(queryKey, checkpointCache);
    },
  });
};

export default useSkipCheckpoint;
