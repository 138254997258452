import { amber, green, red } from '@mui/material/colors';
import {
  createTheme,
  alpha,
  getContrastRatio,
  PaletteColorOptions,
  SxProps,
} from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    scheduledStatusColor: PaletteColorOptions;
    processingStatusColor: PaletteColorOptions;
    processedStatusColor: PaletteColorOptions;
    notificationColor: PaletteColorOptions;
    textBlackColor: PaletteColorOptions;
  }

  interface PaletteOptions {
    scheduledStatusColor: PaletteColorOptions;
    processingStatusColor: PaletteColorOptions;
    processedStatusColor: PaletteColorOptions;
    notificationColor: PaletteColorOptions;
    textBlackColor: PaletteColorOptions;
  }
}

// For some components we need main/light/dark/contrastText variant,
// Please provide the colorBase an colorMain for each new color
// FYI: convert rgba into hexadecimal
const scheduledColorBase = '#9c27b0cc';
const scheduledColorMain = alpha(scheduledColorBase, 0.12);

const processingColorBase = '#0288d1cc';
const processingColorMain = alpha(processingColorBase, 0.12);

const processedColorBase = '#2e7d32cc';
const processedColorMain = alpha(processedColorBase, 0.12);

const notificationColorBase = '#F5F5F5';
const notificationColorMain = alpha(notificationColorBase, 0.7);

const textBlackColorBase = '#000';
const textBlackColorMain = alpha(textBlackColorBase, 0.7);

export const Theme = createTheme({
  typography: {
    fontFamily: 'HelveticaNowText, sans-serif',
    allVariants: {
      color: textBlackColorBase,
    },
    h6: {
      fontSize: 20,
      lineHeight: '32px',
      fontWeight: 500,
    },
    body1: {
      fontSize: 16,
      lineHeight: '24px',
    },
    body2: {
      fontSize: 14,
    },
    subtitle1: {
      fontSize: 20,
      lineHeight: '32px',
      fontWeight: 700,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: 'HelveticaNowText, sans-serif',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => {
          const sx = ownerState.sx as SxProps & { backgroundColor: string };

          if (sx && sx.backgroundColor && ownerState.variant === 'contained') {
            return {
              ':hover': {
                backgroundColor: sx.backgroundColor,
                boxShadow: `0px 2px 4px -1px rgba(0, 0, 0, 0.2),
                 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
                 0px 1px 10px 0px rgba(0, 0, 0, 0.12)`,
              },
            };
          }
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#1976d2',
      light: '#42a5f5',
      dark: '#1565c0',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    success: {
      main: green[500],
    },
    warning: {
      main: amber[700],
    },
    text: {
      secondary: 'rgba(0, 0, 0, 0.6)',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    textBlackColor: {
      main: textBlackColorMain,
      light: alpha(textBlackColorBase, 0.05),
      dark: alpha(textBlackColorBase, 0.9),
      contrastText:
        getContrastRatio(textBlackColorBase, '#FFF') > 4.5 ? '#FFF' : '#111',
    },
    scheduledStatusColor: {
      main: scheduledColorMain,
      light: alpha(scheduledColorBase, 0.05),
      dark: alpha(scheduledColorBase, 0.9),
      contrastText:
        getContrastRatio(scheduledColorMain, '#000') > 4.5 ? '#000' : '#111',
    },
    processingStatusColor: {
      main: processingColorMain,
      light: alpha(processingColorBase, 0.05),
      dark: alpha(processingColorBase, 0.9),
      contrastText:
        getContrastRatio(processingColorMain, '#000') > 4.5 ? '#000' : '#111',
    },
    processedStatusColor: {
      main: processedColorMain,
      light: alpha(processedColorBase, 0.05),
      dark: alpha(processedColorBase, 0.9),
      contrastText:
        getContrastRatio(processedColorMain, '#000') > 4.5 ? '#000' : '#111',
    },
    notificationColor: {
      main: notificationColorBase,
      light: alpha(notificationColorBase, 0.05),
      dark: alpha(notificationColorBase, 0.9),
      contrastText:
        getContrastRatio(notificationColorMain, '#000') > 4.5 ? '#000' : '#111',
    },
  },
});

/*
// Another way of doing it: https://mui.com/material-ui/customization/palette/#generate-tokens-using-augmentcolor-utility

let theme = createTheme({
  // Theme customization goes here as usual, including tonalOffset and/or
  // contrastThreshold as the augmentColor() function relies on these
});

theme = createTheme({
  palette: {
   processingStatusColor: theme.palette.augmentColor({
      color: {
        main: '#ff88d1',
      },
      name: 'processingStatusColor',
    }), 
  }
})

export default theme
*/
