import { useQuery } from '@tanstack/react-query';

import { GET_DEFECT_OPTIONS } from '@/helpers/query-keys';
import { getDefectOptions } from '@/services/qc.service';

export const useGetDefects = (uuid: string) => {
  return useQuery({
    queryKey: [GET_DEFECT_OPTIONS, uuid],
    queryFn: async () => getDefectOptions(uuid),
    enabled: !!uuid,
    staleTime: Infinity,
  });
};
