import { useMutation } from '@tanstack/react-query';
import { UPLOAD_IMAGE } from '@/helpers/query-keys';
import { uploadImage } from '@/services/qc.service';

export const useUploadImage = () => {
  return useMutation({
    mutationKey: [UPLOAD_IMAGE],
    mutationFn: ({
      uuid,
      file,
      token,
    }: {
      uuid: string;
      file: File;
      token?: string | null;
    }) => uploadImage(uuid, file, token),
  });
};
