import {
  DatePickerProps as MuiDatePickerProps,
  DatePicker as MuiDatePicker,
} from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';
import 'dayjs/locale/en-gb';
import { useTranslation } from 'react-i18next';

interface DatePickerProps {
  props: MuiDatePickerProps<Dayjs>;
  required?: boolean;
}

const DatePicker = ({ props, required }: DatePickerProps) => {
  const { t } = useTranslation();

  const isRequired = required && !props.value;

  return (
    <MuiDatePicker
      value={props.value}
      sx={(theme) => ({
        width: '100%',
        '& fieldset': isRequired
          ? {
              borderColor: theme.palette.error.main,
            }
          : undefined,
        '& .MuiFormHelperText-root': {
          color: theme.palette.error.main,
        },
      })}
      slotProps={{
        textField: {
          required: isRequired,
          helperText: isRequired && t('QC_V2.DEFECT.REQUIRED'),
        },
      }}
      {...props}
    />
  );
};

export default DatePicker;
