import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import QcModal from '../Modal/Modal';

interface ConfirmModalProps {
  description?: string | JSX.Element;
  isLoading?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  cancelBtnText?: string;
  confirmBtnText?: string;
  title?: string;
}

const ConfirmModal = ({
  description,
  isLoading,
  cancelBtnText,
  confirmBtnText,
  title,
  onConfirm,
  onCancel,
}: ConfirmModalProps) => {
  const { t } = useTranslation();

  return (
    <QcModal
      open={true}
      maxWidth="xs"
      id="defect-confirm-modal"
      header={title || t('QC_V2.MAIN.CONFIRMATION')}
      disableBackdropClick
      content={
        <Stack gap={2}>
          {typeof description === 'string' ? (
            <Typography variant="body1">{description}</Typography>
          ) : (
            description
          )}

          <Stack flexDirection="row" justifyContent="flex-end" gap={0.5}>
            {!!onCancel && (
              <Button autoFocus onClick={onCancel}>
                {cancelBtnText || t('QC_V2.MAIN.CANCEL')}
              </Button>
            )}

            {!!onConfirm && (
              <Button
                type="submit"
                autoFocus
                onClick={onConfirm}
                disabled={isLoading}
              >
                {confirmBtnText || t('QC_V2.MAIN.CONFIRM')}
              </Button>
            )}
          </Stack>
        </Stack>
      }
    />
  );
};

export default ConfirmModal;
