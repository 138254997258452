import { useMemo } from 'react';
import { defectFieldMap } from '@/helpers/defect-form';
import { Defect, REQUIRED_FIELD } from '@/types/interfaces/defect-details';
import { FormSelectOption } from '@/types/interfaces/form';
import { useGetDefects } from './use-get-defects';
import { useProduct } from '../use-product';

interface SelectOptionProps {
  requiredFields?: REQUIRED_FIELD[];
}

const INITIAL_OPTIONS: Record<keyof Defect, FormSelectOption[]> = {
  colors: [] as FormSelectOption[],
  defectImportances: [] as FormSelectOption[],
  defectQuantities: [] as FormSelectOption[],
  defectSizes: [] as FormSelectOption[],
  materials: [] as FormSelectOption[],
  sizeTypes: [] as FormSelectOption[],
  categoryBrandModels: [] as FormSelectOption[],
};

export const useSelectOptions = ({ requiredFields }: SelectOptionProps) => {
  const { uuid } = useProduct();

  const { data: defects, isLoading } = useGetDefects(uuid);

  const options = useMemo(() => {
    const result = INITIAL_OPTIONS;

    if (!defects || !requiredFields?.length) return result;

    requiredFields.forEach((f) => {
      const fields = defectFieldMap.get(f) || [];

      fields.forEach((keyName) => {
        switch (keyName) {
          case 'colors':
          case 'categoryBrandModels':
          case 'materials':
            result[keyName] = defects[keyName]
              .map((i) => ({
                label: i.name,
                value: i.id.toString(),
              }))
              .sort((a, b) => -b.label.localeCompare(a.label));
            break;
          case 'defectImportances':
          case 'defectQuantities':
          case 'defectSizes':
            result[keyName] = defects[keyName].map((i) => ({
              label: i.label,
              value: i.value,
            }));
            break;
          case 'sizeTypes':
            result.sizeTypes = defects['sizeTypes'].map((i) => ({
              label: i.type,
              value: i.type,
            }));
            break;
          default:
            break;
        }
      });
    });

    return result;
  }, [requiredFields, defects]);

  return { options, isLoading };
};
