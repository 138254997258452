import { Stack } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ActivityTable from '@/components/AgentActivity/ActivityTable';
import GeneralInfo from '@/components/AgentActivity/GeneralInfo';
import { useAuthContext } from '@/context/Authentication.context';
import { getWarehouseLocale } from '@/helpers/unit';
import { useAgentInfo } from '@/hooks/use-agent';
import { useAgentActivity } from '@/hooks/use-agent-activity';

const AgentActivity = () => {
  const { t } = useTranslation();

  const { user: userInfo } = useAuthContext();

  const { agent } = useAgentInfo();

  const { data, isLoading } = useAgentActivity();

  const locale = getWarehouseLocale();

  const generalInfo = [
    {
      label: t('QC_V2.QC_ACT.TOTAL_ITEM'),
      value: data?.productivity.total || 0,
    },
  ];

  const now = new Date();

  const agentInfo = [
    {
      label: t('QC_V2.QC_ACT.AGENT'),
      value: userInfo.given_name,
    },
    {
      label: t('QC_V2.QC_LST.WAREHOUSE'),
      value: agent?.warehouse?.name || '-',
    },
    {
      label: t('QC_V2.QC_ACT.DATE'),
      value: now.toLocaleDateString(locale, {
        month: 'short',
        year: 'numeric',
        day: 'numeric',
      }),
    },
  ];

  const getPercentage = (value: number, total: number) =>
    ((value / total) * 100).toFixed(1).replace(/[.,]0$/, '');

  const qcInfo = useMemo(() => {
    let okValue: string = '',
      negotiatedValue: string = '',
      koValue: string = '';

    if (data) {
      const {
        productivity: { ok = 0, ko = 0, negotiated = 0, total = 0 },
      } = data;

      okValue = ok ? `${ok} (${getPercentage(ok, total)}%)` : '0';

      koValue = ko ? `${ko} (${getPercentage(ko, total)}%)` : '0';

      negotiatedValue = negotiated
        ? `${negotiated} (${getPercentage(negotiated, total)}%)`
        : '0';
    }

    return [
      {
        label: t('QC_V2.MAIN.OK'),
        value: okValue,
      },
      {
        label: t('QC_V2.MAIN.QC_NEGO'),
        value: negotiatedValue,
      },
      {
        label: t('QC_V2.MAIN.KO'),
        value: koValue,
      },
    ];
  }, [data]);

  return (
    <Stack pt={3} px={3}>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        borderBottom="1px solid rgba(224, 224, 224, 1)"
      >
        <Stack flexDirection="row" gap={3.5}>
          <GeneralInfo info={generalInfo} alignCenter />
          <GeneralInfo info={qcInfo} alignCenter />
        </Stack>

        <GeneralInfo info={agentInfo} />
      </Stack>

      <ActivityTable
        qualityControls={data?.qualityControls || []}
        isLoading={isLoading}
      />
    </Stack>
  );
};

export default AgentActivity;
