import { TFunction } from 'i18next';
import { UseFormSetError } from 'react-hook-form';
import {
  DynamicFormValues,
  PhotoFormType,
} from '@/components/DefectDetailModal/DefectDetails';
import {
  Defect,
  DefectRequest,
  Dimension,
  PHOTO_TYPE,
  REQUIRED_FIELD,
  SubmittedDefect,
  DimensionRequest,
  SortDynamicFieldType,
  FormDimension,
} from '@/types/interfaces/defect-details';
import { FormSelectOption } from '@/types/interfaces/form';
import { LENGTH_FORMAT, toLengthUnit } from './unit';

export const dynamicFormDefaultValues: DynamicFormValues = {
  defect: null,
  comment: '',
  defectImportance: null,
  defectQuantity: null,
  defectSize: null,
  color: null,
  categoryBrandModel: null,
  sizeType: null,
  size: null,
  material1: null,
  material1Percent: '',
  material2: null,
  material2Percent: '',
  weight: '',
  dimensions: [],
  isAppearedInPhoto: false,
};

export const defectFieldMap: Map<REQUIRED_FIELD, (keyof Defect)[]> = new Map([
  [
    REQUIRED_FIELD.IMPORTANCE,
    ['defectImportances', 'defectSizes', 'defectQuantities'],
  ],
  [REQUIRED_FIELD.COLOR, ['colors']],
  [REQUIRED_FIELD.MATERIAL, ['materials']],
  [REQUIRED_FIELD.SIZE, ['sizeTypes']],
  [REQUIRED_FIELD.MODEL, ['categoryBrandModels']],
]);

export const dynamicFormFieldMap: Map<
  REQUIRED_FIELD,
  (keyof DynamicFormValues)[]
> = new Map([
  [
    REQUIRED_FIELD.IMPORTANCE,
    ['defectImportance', 'defectQuantity', 'defectSize'],
  ],
  [REQUIRED_FIELD.COLOR, ['color']],
  [
    REQUIRED_FIELD.MATERIAL,
    ['material1', 'material1Percent', 'material2', 'material2Percent'],
  ],
  [REQUIRED_FIELD.SIZE, ['sizeType', 'size']],
  [REQUIRED_FIELD.WEIGHT, ['weight']],
  [REQUIRED_FIELD.DIMENSION, ['dimensions']],
  [REQUIRED_FIELD.MODEL, ['categoryBrandModel']],
]);

export const defectSubmissionMap = (
  data: DynamicFormValues & { photos: PhotoFormType[] }
) => {
  const dimensions = data.dimensions?.length
    ? data.dimensions.filter((d) => !!d && d.value)
    : undefined;

  const result: DefectRequest = {
    defectTypeId: data.defect?.id || 0,
    photos: data.photos.length
      ? data.photos
          .filter((p) => !!p && p.checked && !p.isHidden)
          .map((p) => {
            return {
              path: p.path || '',
              annotations:
                p.annotations?.map((a) => ({ x: a.x / 100, y: a.y / 100 })) ||
                [],
              type: p.type === 'produit' ? 'original' : 'uploaded',
            };
          })
      : undefined,
    isAppearedInPhoto: data.isAppearedInPhoto,
    internalComment: data.comment,
    defectImportance: data.defectImportance?.value,
    defectQuantity: data.defectQuantity?.value,
    defectSize: data.defectSize?.value,
    size: data.size?.value,
    sizeType: data.sizeType?.value,
    weight: data.weight ? parseFloat(data.weight) : undefined,
    color: data.color?.value ? parseInt(data.color.value) : undefined,
    categoryBrandModel: data.categoryBrandModel?.value
      ? parseInt(data.categoryBrandModel.value)
      : undefined,
    material1: data.material1?.value
      ? parseInt(data.material1.value)
      : undefined,
    material1Percent: data.material1Percent
      ? parseFloat(data.material1Percent)
      : undefined,
    material2: data.material2?.value
      ? parseInt(data.material2.value)
      : undefined,
    material2Percent: data.material2Percent
      ? parseFloat(data.material2Percent)
      : undefined,
    dimensions: dimensions as DimensionRequest,
  };

  Object.keys(result).forEach(
    (key) =>
      result[key as keyof DefectRequest] === undefined &&
      delete result[key as keyof DefectRequest]
  );

  return result;
};

export const checkHavingDimension = (
  setError: UseFormSetError<DynamicFormValues>,
  t: TFunction<'translation', undefined>,
  dimensions: FormDimension,
  requiredFields?: REQUIRED_FIELD[]
) => {
  const isRequireDimensions = requiredFields?.some(
    (f) => f === REQUIRED_FIELD.DIMENSION
  );

  if (!isRequireDimensions) return true;

  const hasDimension = dimensions.some((d) => !!d?.id && !!d.value);

  if (!hasDimension) {
    setError('dimensions', {
      message: t('QC_V2.DEFECT.DIMENSION_ONE_INPUT_VAL'),
    });
    return false;
  }

  return true;
};

export const validateDefectPhotos = (
  setError: UseFormSetError<DynamicFormValues>,
  photos: PhotoFormType[],
  t: TFunction<'translation', undefined>,
  requiredFields?: REQUIRED_FIELD[]
) => {
  if (!requiredFields?.length) return true;

  const allPhotos = photos.filter((p) => !!p && p.checked);

  const isPhotoRequired = requiredFields?.includes(REQUIRED_FIELD.PICTURE);

  const hasUploadedPics =
    allPhotos.findIndex((p) => p.type === PHOTO_TYPE.NPV) > -1;

  if (isPhotoRequired && !hasUploadedPics) {
    setError('pdpPhotos', { message: t('QC_V2.DEFECT.UPLOAD_PHOTO_REQUIRED') });
    return false;
  }

  const isAnnotationRequired = requiredFields?.includes(
    REQUIRED_FIELD.LOCATION
  );

  const hasAnnotation =
    allPhotos.findIndex((p) => !!p.annotations?.length) > -1;

  if (isAnnotationRequired && !hasAnnotation) {
    setError('pdpPhotos', { message: t('QC_V2.DEFECT.ANNOTATION_REQUIRED') });
    return false;
  }

  return true;
};

export const defectDefaultValueMap = ({
  data,
  dimensions,
}: {
  data: SubmittedDefect;
  dimensions?: Dimension[];
}): DynamicFormValues => {
  let produitPhotos: PhotoFormType[] = [];
  let npvPhotos: PhotoFormType[] = [];

  if (data.photos?.length) {
    const photos = data.photos.map((p, idx) => {
      const annotations = p.annotations.map((a) => ({
        x: a.x * 100,
        y: a.y * 100,
      }));
      return { ...p, annotations, id: idx };
    });

    produitPhotos = photos
      .filter((p) => p.type === 'original')
      .map((p) => ({ ...p, type: PHOTO_TYPE.PRODUIT }));

    npvPhotos = photos
      .filter((p) => p.type === 'uploaded')
      .map((p) => ({ ...p, type: PHOTO_TYPE.NPV }));
  }

  const bucket = data.defectType?.bucket;

  const lengthUnit = toLengthUnit();

  const mappedDimensions =
    dimensions && dimensions.length
      ? dimensions.map((d) => {
          const item = data.dimensions?.find(
            (i) => i.typeDimension?.id === d.id
          );
          if (!item) return undefined;

          const value =
            lengthUnit === LENGTH_FORMAT.CM.toLowerCase()
              ? item.value
              : item.valueInch;

          return { id: d.id, value: value || '' };
        })
      : [];

  const result = {
    defect:
      data.defectType && bucket
        ? {
            label: data.defectType.name,
            id: data.defectType.id,
            bucketId: bucket.id,
            bucketName: bucket.name,
            requiredFields: bucket?.requiredFields,
            optionalFields: bucket?.optionalFields,
          }
        : undefined,
    comment: data.internalComment,

    defectImportance: data.defectImportance
      ? {
          label: data.defectImportance.label,
          value: data.defectImportance.value,
        }
      : undefined,
    defectQuantity: data.defectQuantity
      ? {
          label: data.defectQuantity.label,
          value: data.defectQuantity.value,
        }
      : undefined,
    defectSize: data.defectSize
      ? {
          label: data.defectSize.label,
          value: data.defectSize.value,
        }
      : undefined,

    color: data.color
      ? { label: data.color.name, value: data.color.id.toString() }
      : undefined,
    categoryBrandModel: data.categoryBrandModel
      ? {
          label: data.categoryBrandModel.name,
          value: data.categoryBrandModel.id.toString(),
        }
      : undefined,

    sizeType: data.sizeType
      ? { label: data.sizeType, value: data.sizeType }
      : undefined,
    size: data.size ? { label: data.size, value: data.size } : undefined,

    material1: data.material1
      ? { label: data.material1.name, value: data.material1.id.toString() }
      : undefined,
    material1Percent: data.material1Percent?.toString(),

    material2: data.material2
      ? { label: data.material2.name, value: data.material2.id.toString() }
      : undefined,
    material2Percent: data.material2Percent?.toString(),

    weight: data.weight?.toString(),

    dimensions: mappedDimensions,

    pdpPhotos: produitPhotos,
    uploadedPhotos: npvPhotos,

    isAppearedInPhoto: data.isAppearedInPhoto,
  };

  Object.keys(result).forEach(
    (key) =>
      result[key as keyof DynamicFormValues] === undefined &&
      delete result[key as keyof DynamicFormValues]
  );

  return result;
};

export const checkRequired = (
  required: string | undefined,
  options: FormSelectOption[]
) => {
  if (!options.length) return undefined;
  return required;
};

const ORDERED_FIELDS = [
  REQUIRED_FIELD.MODEL,
  REQUIRED_FIELD.COLOR,
  REQUIRED_FIELD.WEIGHT,
  REQUIRED_FIELD.MATERIAL,
  REQUIRED_FIELD.SIZE,
  REQUIRED_FIELD.DIMENSION,
  REQUIRED_FIELD.IMPORTANCE,
  REQUIRED_FIELD.PICTURE,
  REQUIRED_FIELD.LOCATION,
];

export const getDynamicFields = (
  respondedFields: REQUIRED_FIELD[],
  mappingFields: Record<REQUIRED_FIELD, JSX.Element>
) => {
  return respondedFields.reduce((acc, current) => {
    const el = mappingFields[current];
    if (el) acc.push({ type: current, element: el });
    return acc;
  }, [] as SortDynamicFieldType);
};

export const toSortedDynamicFields = (fields: SortDynamicFieldType) =>
  fields
    .sort(
      (a, b) => ORDERED_FIELDS.indexOf(a.type) - ORDERED_FIELDS.indexOf(b.type)
    )
    .map((f) => f.element);
