import styled from '@emotion/styled';
import { Box, Grid } from '@mui/material';
import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { ProductInfo } from '@/components/ProductInfo/ProductInfo';
import { QcPanel } from '@/components/QcPanel/QcPanel';
import QcPanelModal from '@/components/QcPanelModal/QcPanelModal';
import { useAuthContext } from '@/context/Authentication.context';
import { ModalProvider } from '@/context/Modal.context';
import {
  useQualityControlTool,
  SetDefectPayloadType,
} from '@/context/Qualitycontrol.context';
import { RoutesMapping } from '@/helpers/routing';
import { QC_PRODUCT_STATE } from '@/helpers/states';
import { useProduct } from '@/hooks/use-product';
import { useQcMode } from '@/hooks/use-qcMode';
import { QcInfoType } from '@/types/QcInfo';

import './index.scss';

const ProductInfoGrid = styled(Grid)`
  background-color: #f5f5f5;

  &.MuiGrid-item {
    padding: 2rem 1rem 2rem 2rem;
  }
`;

const QcPanelGrid = styled(Grid)`
  &.MuiGrid-item {
    padding-left: 0;
  }
`;

export const QualityControlTool = () => {
  const { id } = useParams();
  const { isViewMode } = useQcMode();
  const { qcData, productId, setProductId, reset } = useProduct();
  const { user: userInfo } = useAuthContext();
  const navigate = useNavigate();
  const qcInfo = qcData as QcInfoType;
  const {
    setAdditionalItems,
    setDefect,
    setHallmarks,
    setWeight,
    setRefurbishment,
    setAgentComment,
  } = useQualityControlTool();

  useEffect(() => {
    // If page is in view mode, allow users to view the page content
    if (isViewMode) {
      return;
    }

    // Check when qcInfo is loaded
    // Redirect to home if the product is controlled by others
    const qcAgentEmail = qcInfo?.agent?.email;

    if (qcInfo && Object.keys(qcInfo).length) {
      if (
        qcInfo.state !== QC_PRODUCT_STATE.BEING_CONTROLLED ||
        (qcAgentEmail && qcAgentEmail !== userInfo.email)
      ) {
        setProductId('');
        reset();
        navigate(RoutesMapping.HOME, { replace: true });
      }
    }
  }, [qcData]);

  useEffect(() => {
    if (id && id !== productId) {
      setProductId(id);
    }
  }, [productId, id, setProductId]);

  useEffect(() => {
    // For qc result view page, set qc info to the fields
    if (qcInfo.record) {
      const { record } = qcInfo;
      const recordsDetails = (record.checkpointRecords || []).reduce(
        (acc, checkpointRecords) => {
          const defectRecords = checkpointRecords.defectRecords || [];

          const recordsInfo = defectRecords.map((item) => {
            return {
              ...item,
              checkPointId: checkpointRecords.checkpoint.id,
            };
          });

          return acc.concat(...recordsInfo);
        },
        [] as SetDefectPayloadType['data']
      );

      setDefect({
        data: recordsDetails || [],
      });
      setAdditionalItems(
        (record.additionalItemRecords || []).map((item) => item.additionalItem)
      );
      setHallmarks(record.hallmarks || '');
      setWeight(String(record.weight || ''));
      setRefurbishment(record.isRefurbished);
      setAgentComment(record.agentComment || '');
    }
  }, [qcInfo]);

  return (
    <ModalProvider>
      <Grid container spacing={2} className="QualityControl_tool">
        <ProductInfoGrid
          item
          md={4}
          className="QualityControl_tool__productInfo"
          sx={{
            height: 'calc(100vh - 2.75rem)',
            overflow: 'scroll',
            scrollbarWidth: 'thin',
          }}
        >
          <ProductInfo />
          {/* This box is for the space of the icon menu */}
          <Box sx={{ height: 45 }} />
        </ProductInfoGrid>

        <QcPanelGrid item md={8}>
          <QcPanel />
        </QcPanelGrid>
      </Grid>
      <QcPanelModal />
    </ModalProvider>
  );
};
