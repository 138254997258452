import { useQuery } from '@tanstack/react-query';
import { useState, createContext, FC, ReactNode, useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import { SCAN_PRODUCT } from '@/helpers/query-keys';
import { RoutesMapping } from '@/helpers/routing';
import { scanProduct } from '@/services/qc.service';
import { CustomError } from '@/types/AxiosError';
import { QcInfoType, QcErrorType } from '@/types/QcInfo';

export type NoProductQcType = {
  noProductQc: boolean;
};

export type QcDataType = QcInfoType | QcErrorType | NoProductQcType;

interface ProductContextType {
  qcData: QcInfoType | {};
  setQcData: (qcData: QcDataType) => void;
  reset: () => void;
  productId: string;
  setProductId: (productId: string) => void;
  count: number;
}

const initialState = {};

interface ProductProviderProps {
  children: ReactNode;
}

export const ProductContext = createContext<ProductContextType>(
  initialState as ProductContextType
);

export const ProductProvider: FC<ProductProviderProps> = ({ children }) => {
  const [qcData, setQcData] = useState<QcInfoType | {}>({});
  const [productId, setProductId] = useState<string>('');
  // Use this special count value to trigger rerender when search again with same productId
  const [count, setCount] = useState<number>(0);
  const { pathname } = useLocation();
  const isHomePage = pathname === RoutesMapping.HOME;

  const {
    data: qcInfo,
    isSuccess,
    isError,
    error,
    refetch: refetchProductInfo,
    dataUpdatedAt,
  } = useQuery<any, CustomError>({
    // Do not put isHomePage as queryKey dependancy, it will cause auto refecth on
    // page transition between homepage and qc tool page
    queryKey: [SCAN_PRODUCT, productId],
    queryFn: () => scanProduct(productId, isHomePage),
    retry: false,
    enabled: !!productId,
  });

  useEffect(() => {
    if (isSuccess) {
      if (qcInfo.length) {
        setQcData(qcInfo[0]);
      } else {
        setQcData({
          noProductQc: true,
        });
      }
    }

    if (isError && error.response?.data) {
      setQcData(error.response.data);
    }
  }, [qcInfo, error, dataUpdatedAt]);

  useEffect(() => {
    if (productId) {
      refetchProductInfo();
    }
  }, [productId, count]);

  const reset = () => {
    setQcData({});
  };

  const updateProductId = (id: string) => {
    // A new request should be sent out when count is updated
    setCount(count + 1);
    setProductId(id.trim());
    reset();
  };

  const values = {
    productId,
    qcData,
    setQcData,
    reset,
    setProductId: updateProductId,
    count,
  };

  return (
    <ProductContext.Provider value={values}>{children}</ProductContext.Provider>
  );
};
