import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { FormField } from '@/types/interfaces/form';

interface CheckboxFormProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> extends FormField<TFieldValues, TName> {
  checkboxProps?: CheckboxProps;
}

const FormCheckbox = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  name,
  label,
  control,
  checkboxProps,
}: CheckboxFormProps<TFieldValues, TName>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, ...field } }) => (
        <FormGroup>
          <FormControlLabel
            control={<Checkbox {...field} checked={value} {...checkboxProps} />}
            label={label}
          />
        </FormGroup>
      )}
    />
  );
};

export default FormCheckbox;
