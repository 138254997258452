import { useController, useFormContext } from 'react-hook-form';
import { PHOTO_TYPE } from '@/types/interfaces/defect-details';
import DefectMarkingPhoto from './DefectMarkingPhoto';
import { SelectedPhotoType } from './PhotoForm';
import { DynamicFormValues } from '../DefectDetails';

export interface DefectMarkingProps {
  photo: SelectedPhotoType;
  disabled: boolean;
}

const DefectMarking = ({ photo, disabled }: DefectMarkingProps) => {
  const { control, setValue, getValues } = useFormContext<DynamicFormValues>();

  const { field: uploadedPhotoField } = useController({
    control,
    name: 'uploadedPhotos',
  });

  const { field: pdpPhotoField } = useController({
    control,
    name: 'pdpPhotos',
  });

  if (!photo) return null;

  return (
    <DefectMarkingPhoto
      photo={photo}
      field={
        photo.type === PHOTO_TYPE.PRODUIT ? pdpPhotoField : uploadedPhotoField
      }
      setValue={setValue}
      getValues={getValues}
      disabled={disabled}
    />
  );
};

export default DefectMarking;
