import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { FieldPath, useFormContext } from 'react-hook-form';
import FormCheckbox from '../../Common/Form/FormCheckbox';
import { DynamicFormValues } from '../DefectDetails';
import PhotoBox from '../PhotoBox';

export interface PhotoProps {
  imgUrl: string;
  name?: FieldPath<DynamicFormValues>;
  onClick?: () => void;
  onChange?: (checked: boolean) => void;
  onDelete?: () => void;
  isChecked?: boolean;
  isClicked?: boolean;
}

const PhotoItem = ({
  imgUrl,
  name,
  isChecked,
  isClicked,
  onChange,
  onClick,
  onDelete,
}: PhotoProps) => {
  const { control } = useFormContext<DynamicFormValues>() || {};

  const [checked, setChecked] = useState(false);

  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    if (isClicked !== undefined) setClicked(isClicked);
  }, [isClicked]);

  useEffect(() => {
    if (isChecked !== undefined) setChecked(isChecked);
  }, [isChecked]);

  const handleCheck = () => {
    if (!onChange) return;
    setChecked(!checked);
    onChange(!checked);
  };

  const handleClick = () => {
    if (onClick) onClick();
  };

  return (
    <PhotoBox imgUrl={imgUrl} clicked={clicked}>
      {!!name && !!control && (
        <FormCheckbox
          control={control}
          name={name}
          checkboxProps={{
            sx: {
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 1,
              background: 'white',
              borderRadius: 0,
              padding: '0.5px',
              opacity: checked ? 'unset' : 0.85,
              ':hover': {
                background: 'white',
              },
            },
            checked,
            onChange: handleCheck,
          }}
        />
      )}

      {!!onDelete && (
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            zIndex: 1,
          }}
        >
          <IconButton
            aria-label="delete"
            size="small"
            sx={{
              background: 'white',
              borderRadius: 0,
              p: 0.5,
              opacity: 0.5,
              ':hover': {
                background: 'white',
                opacity: 1,
              },
            }}
            onClick={onDelete}
          >
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        </Box>
      )}

      <Box
        sx={{ width: '100%', height: '100%', position: 'absolute', zIndex: 0 }}
        component="div"
        onClick={handleClick}
      />
    </PhotoBox>
  );
};

export default PhotoItem;
