import { Box, CircularProgress } from '@mui/material';
import PhotoBox from './PhotoBox';

const LoadingBox = () => {
  return (
    <PhotoBox>
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress size={32} />
      </Box>
    </PhotoBox>
  );
};

export default LoadingBox;
