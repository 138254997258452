import { useMutation } from '@tanstack/react-query';
import { SUBMIT_DEFECT } from '@/helpers/query-keys';
import { submitDefect } from '@/services/qc.service';
import { CustomError } from '@/types/AxiosError';
import {
  DefectRequest,
  SubmittedDefect,
} from '@/types/interfaces/defect-details';

type SubmitDefectType = { uuid: string; data: DefectRequest };

export const useSubmitDefect = () => {
  return useMutation<SubmittedDefect, CustomError, SubmitDefectType>({
    mutationKey: [SUBMIT_DEFECT],
    mutationFn: ({ uuid, data }: SubmitDefectType) => submitDefect(uuid, data),
  });
};
