import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { IconButton, SxProps, Theme } from '@mui/material';

interface DrawerButtonProps {
  open: boolean;
  onClick: () => void;
  sx?: SxProps<Theme>;
}

const DrawerButton = ({ open, onClick, sx }: DrawerButtonProps) => {
  return (
    <IconButton
      onClick={onClick}
      size="large"
      sx={{
        position: 'absolute',
        zIndex: 1000,
        color: (theme: Theme) => theme.palette.common.black,
        ...sx,
      }}
    >
      {open ? (
        <KeyboardArrowLeftIcon fontSize="inherit" />
      ) : (
        <KeyboardArrowRightIcon fontSize="inherit" />
      )}
    </IconButton>
  );
};

export default DrawerButton;
