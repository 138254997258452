import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';
import { BOX_SIZE } from './PhotoForms/PhotoForm';

const PhotoBox = ({
  imgUrl,
  clicked,
  width,
  height,
  children,
}: PropsWithChildren & {
  imgUrl?: string;
  clicked?: boolean;
  width?: number;
  height?: number;
}) => {
  return (
    <Box
      sx={{
        width: width || BOX_SIZE,
        height: height || BOX_SIZE,
        background: imgUrl ? `url(${imgUrl})` : 'unset',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        position: 'relative',
        zIndex: 0,
        border: clicked
          ? '2px solid rgba(30, 136, 229, 1)'
          : '1px solid rgba(0, 0, 0, 0.12)',
      }}
      component="div"
    >
      {children}
    </Box>
  );
};

export default PhotoBox;
