import { QC_PRODUCT_STATE } from '@/helpers/states';
import { useProduct } from '@/hooks/use-product';
import { QcInfoType } from '@/types/QcInfo';
import TakeOverHoldItem from './TakeOverHoldItem';

export default function ScanItemModals() {
  const { qcData } = useProduct();
  const qcInfo = qcData as QcInfoType;
  const itemState = qcInfo.state;

  if (itemState === QC_PRODUCT_STATE.HELD) {
    return <TakeOverHoldItem />;
  }

  return null;
}
