import { datadogRum } from '@datadog/browser-rum';
import Cookies from 'cookies-ts';
import { jwtDecode } from 'jwt-decode';
import { FC, createContext, ReactNode, useContext, useEffect } from 'react';

import { environments } from '@/environments';
import { IdToken } from '@/types/global';

export interface AuthContextType {
  isAuthenticated: boolean;
  idToken: IdToken | null;
  accessToken: string | null;
  logout: () => void;
  user: {
    family_name: string;
    given_name: string;
    email: string;
  };
}

interface AuthProviderProps {
  children: ReactNode;
}

const COOKIE_ACCESS_TOKEN = 'access_token';
const COOKIE_ID_TOKEN = 'id_token';

export const AuthContext = createContext<AuthContextType>(
  {} as AuthContextType
);

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const cookies = new Cookies();
  const accessToken = cookies.get(COOKIE_ACCESS_TOKEN);
  const idToken = cookies.get(COOKIE_ID_TOKEN);
  const decodedToken = idToken ? jwtDecode<IdToken>(idToken) : null;
  let isAuthenticated = false;
  const { family_name, given_name, email } = decodedToken || {};

  if (!decodedToken || !decodedToken.exp) {
    isAuthenticated = false;
  } else {
    isAuthenticated = new Date() <= new Date(decodedToken.exp * 1000);
  }

  const logout = () => {
    const cookies = new Cookies();
    cookies.remove(COOKIE_ACCESS_TOKEN, {
      path: '/',
      domain: environments.cookieDomain,
    });
    cookies.remove(COOKIE_ID_TOKEN, {
      path: '/',
      domain: environments.cookieDomain,
    });
    setTimeout(() => {
      window.location.reload();
    }, 200);
  };

  useEffect(() => {
    datadogRum.setUser({
      name: `${given_name} ${family_name}`,
      email: email,
    });
  }, [decodedToken]);

  const values: AuthContextType = {
    isAuthenticated,
    idToken: decodedToken,
    user: {
      family_name: family_name || '',
      given_name: given_name || '',
      email: email || '',
    },
    accessToken,
    logout,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuthContext must be used within an AuthProvider');
  }

  return context;
};
