import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

export default function AdditionalInfoSection({
  title,
  content,
}: {
  title: string;
  content: string | React.ReactNode;
}) {
  return (
    <Container sx={{ mb: 2 }}>
      <Typography variant="overline" display="block" sx={{ mb: 1 }}>
        {title}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {content}
      </Box>
    </Container>
  );
}
