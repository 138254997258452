export const GET_BUCKETS = 'GET_BUCKETS_QUERY';

export const GET_DEFECT_OPTIONS = 'GET_DEFECT_OPTIONS_QUERY';

export const GET_DIMENSION_TYPES = 'GET_DIMENSION_TYPES_QUERY';

export const UPLOAD_IMAGE = 'UPLOAD_IMAGE_MUTATION';

export const SUBMIT_DEFECT = 'SUBMIT_DEFECT_MUTATION';

export const GET_AGENT_ACTIVITY = 'GET_AGENT_ACTIVITY_QUERY';

export const GET_AGENT_INFO = 'GET_AGENT_INFO_QUERY';

export const GET_PRODUCT_COMMENT = 'GET_PRODUCT_COMMENT_QUERY';

export const UPDATE_QC_STATE = 'UPDATE_QC_STATE_MUTATION';

export const GET_CHECK_LIST = 'GET_CHECK_LIST_QUERY';

export const GET_WAREHOUSES = 'GET_WAREHOUSES_QUERY';

export const SCAN_PRODUCT = 'SCAN_PRODUCT_MUTATION';

export const GET_ADDITIONAL_ITEMS = 'GET_ADDITIONAL_ITEMS_QUERY';

export const GET_QC_STATES = 'GET_QC_STATES_QUERY';

export const GET_STANDBY_REASONS = 'GET_STANDBY_REASONS_QUERY';

export const GET_AUTH_INFO = 'GET_AUTH_INFO_QUERY';

export const GET_QC_LIST = 'GET_QC_LIST_QUERY';
