import { Stack } from '@mui/material';
import InfoItem from './InfoItem';

const GeneralInfo = ({
  info,
  alignCenter,
}: {
  info: { label: string; value: string | number }[];
  alignCenter?: boolean;
}) => {
  return (
    <Stack flexDirection="row" gap={3.5} pb={1.5} px={1.5}>
      {info.map((i, idx) => (
        <InfoItem
          key={`${i.label}-${idx}`}
          label={i.label}
          value={i.value}
          alignCenter={alignCenter}
        />
      ))}
    </Stack>
  );
};

export default GeneralInfo;
