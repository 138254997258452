import { Button } from '@mui/material';

interface ZoomButtonProps {
  btnName: string;
  onClick: () => void;
}

const ZoomButton = ({ btnName, onClick }: ZoomButtonProps) => {
  return (
    <Button
      variant="outlined"
      sx={{
        fontSize: 15,
        color: 'white',
        borderColor: 'white',
        ':hover': { borderColor: 'white' },
        minWidth: 107,
      }}
      onClick={onClick}
    >
      {btnName}
    </Button>
  );
};

export default ZoomButton;
