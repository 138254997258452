import { Button, Link, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProductInfoItem from '@/components/ProductInfo/ProductInfoItem';
import { environments } from '@/environments';
import { useProduct } from '@/hooks/use-product';
import { useProductComments } from '@/hooks/use-product-comments';
import { ProductType } from '@/types/ProductInfo';
import { getPriceFormatted } from '@/utils/helpers';
import CommentModal from './CommentModal';
import ProductImage, { MAX_VISIBLE_IMAGE_COUNT } from './ProductImage';
import PublicComment from './PublicComment';
import SectionTitle from './SectionTitle';
import ViewMoreText from '../Common/ViewMoreText/ViewMoreText';
import './ProductInfo.scss';

const InfoBlock = styled(Box)`
  padding: 1rem 0.5rem;
  width: 100%;
  display: flex;
  border-bottom: 1px solid #e0e0e0;

  &:last-child {
    border-bottom: none;
  }
`;

const SellerTypeButton = styled(Button)`
  border-color: #BDBDBD;
  border-radius: 100px;
  color: rgba(0, 0, 0, 0.6);
  background-color: transparent;
  display: block;
  height: auto;
  line-height: 1;
  margin-right: 0.5rem;
  display: inline-block;
  text-align: center;
  font-size: 0.75rem;
  padding: 5px 8px;
  margin-top: 4px;
  min-width: 50px;
  cursor: text;

  &:hover {
    border-color: #BDBDBD;
    background-color: transparent;
  },
`;

export const ProductInfo = () => {
  const { t } = useTranslation();

  const { product, uuid } = useProduct();

  const { data: productComments } = useProductComments(uuid, product?.id);
  const [showTranslatedComments, setShowTranslatedComments] = useState(true);

  const [openComments, setOpenComments] = useState(false);

  const comments: JSX.Element[] = useMemo(() => {
    if (!productComments || !productComments.length) return [];

    return productComments.reduce((acc, curr, idx) => {
      if (curr.description && curr.customerEmail) {
        let fullName = '';

        if (!curr.customerFirstName && !curr.customerLastName) {
          fullName = curr.customerEmail;
        } else {
          fullName = `${curr.customerFirstName || ''} ${curr.customerLastName || ''}`;
        }

        acc.push(
          <PublicComment
            key={idx}
            description={
              showTranslatedComments ? curr.translation : curr.description
            }
            customerName={fullName}
            createdAt={curr.createdAt}
            showDot={productComments.length > 1}
          />
        );
      }
      return acc;
    }, [] as JSX.Element[]);
  }, [productComments, showTranslatedComments]);

  if (!product || !product.id) return null;

  const handleOpenComments = () => setOpenComments(!openComments);

  const productInfo = product as ProductType;

  const { description, comment: internalComment } = productInfo;

  const boProductLink = productInfo.id
    ? `${environments.legacyBoUrl}/admin.php?search=${productInfo.id}&EZBO_page=scan_qualite_v2&section=1`
    : '#';

  const fenxSellerInfoPage = `${environments.fenx}/profile/${product?.seller?.id}/?sortBy=relevance&tab=items-for-sale`;

  // filter out the first three images from product.images
  const displayedImgs = (product.images || []).slice(
    0,
    MAX_VISIBLE_IMAGE_COUNT
  );
  const sellerStatus = product.seller?.flagsV2?.filter(
    (item) => item.type === 'status'
  );

  const isNotForSale = !!product?.notForSaleReasons?.length;

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="nowrap"
        data-testid="product_info_images"
        sx={{
          width: '100%',
          overflowX: 'auto',
        }}
      >
        {displayedImgs.map((img, index) => (
          <ProductImage
            key={index}
            imagePath={img}
            index={index}
            count={
              index === displayedImgs.length - 1 ? product.images.length : 0
            }
          />
        ))}
      </Box>

      <div className="productInfo_blocks-container">
        <InfoBlock className="productInfo_blocks-block">
          <ProductInfoItem
            primary={productInfo.brand}
            secondary={
              <>
                <Typography variant="body2" noWrap={false}>
                  {productInfo.category}
                </Typography>
                <Typography variant="body2">
                  {getPriceFormatted(productInfo.price)}
                </Typography>
              </>
            }
          />

          <ProductInfoItem
            primary={t('QC_V2.QC_LST.PRODUCT_ID')}
            secondary={
              <Link
                href={boProductLink}
                target="_blank"
                sx={{ textDecorationColor: 'rgba(0, 0, 0, 0.6)' }}
              >
                {productInfo.id}
              </Link>
            }
          />

          <ProductInfoItem
            primary={t('QC_V2.QC_LST.PRODUCT_CONDITION')}
            secondary={productInfo.condition}
          />
        </InfoBlock>

        <InfoBlock className="productInfo_blocks-block—small">
          <ProductInfoItem
            primary={t('QC_V2.MAIN.SELLER')}
            secondary={
              <>
                <Link
                  href={fenxSellerInfoPage}
                  target="_blank"
                  sx={{ textDecorationColor: 'rgba(0, 0, 0, 0.6)' }}
                >
                  {!productInfo?.seller?.firstname &&
                  !productInfo?.seller?.lastname
                    ? '-'
                    : `${productInfo?.seller?.firstname}  ${productInfo?.seller?.lastname}`}
                </Link>
                <div>
                  {productInfo?.seller?.type && (
                    <SellerTypeButton variant="outlined">
                      {productInfo.seller.type}
                    </SellerTypeButton>
                  )}
                  {sellerStatus?.length ? (
                    <SellerTypeButton variant="outlined">
                      {sellerStatus[0].name}
                    </SellerTypeButton>
                  ) : null}
                </div>
              </>
            }
            sx={{
              wordWrap: 'break-word',
            }}
          />
          <ProductInfoItem
            primary={t('QC_V2.MAIN.SHIPPING_TO')}
            secondary={productInfo.deliveryCountry?.name || '-'}
          />
          <ProductInfoItem
            primary={t('QC_V2.MAIN.SERIAL_NUMBER')}
            secondary={productInfo.serialNumber || '-'}
          />

          {isNotForSale && (
            <ProductInfoItem
              primary={t('QC_V2.MAIN.NOT_FOR_SALE')}
              secondary={
                <>
                  {productInfo.notForSaleReasons.map((item, index) => (
                    <Typography
                      key={index}
                      variant="body2"
                      color={(theme) =>
                        `${theme.palette.error.main} !important`
                      }
                      sx={{ wordBreak: 'break-word' }}
                    >
                      {item}
                    </Typography>
                  ))}
                </>
              }
            />
          )}
        </InfoBlock>

        <InfoBlock>
          <ProductInfoItem
            primary={
              <SectionTitle
                title={t('QC_V2.MAIN.DESCRIPTION')}
                onClick={handleOpenComments}
              />
            }
            secondary={
              <ViewMoreText
                textClassName="productInfo_item-content"
                buttonClassName="productInfo_item-viewMore"
                text={productInfo.description || '-'}
              />
            }
          />
        </InfoBlock>

        <InfoBlock>
          <ProductInfoItem
            primary={
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <SectionTitle
                  title={t('QC_V2.MAIN.COMMENT')}
                  onClick={handleOpenComments}
                />
                <Button
                  onClick={() =>
                    setShowTranslatedComments(!showTranslatedComments)
                  }
                >
                  {showTranslatedComments
                    ? t('QC_V2.MAIN.SHOW_ORIGINAL_PRODUCT_COMMENT')
                    : t('QC_V2.MAIN.SHOW_TRANSLATED_PRODUCT_COMMENT')}
                </Button>
              </Box>
            }
            secondary={
              <ViewMoreText
                textClassName="productInfo_item-content"
                buttonClassName="productInfo_item-viewMore"
                text={
                  comments.length ? (
                    <Stack gap={1}>{comments.map((c) => c)}</Stack>
                  ) : (
                    '-'
                  )
                }
              />
            }
          />
        </InfoBlock>

        <InfoBlock>
          <ProductInfoItem
            primary={
              <SectionTitle
                title={t('QC_V2.MAIN.AUTH_CMT')}
                onClick={handleOpenComments}
              />
            }
            secondary={
              <ViewMoreText
                textClassName="productInfo_item-content"
                initiallyExpanded={true}
                buttonClassName="productInfo_item-viewMore"
                text={productInfo.comment || '-'}
              />
            }
          />
        </InfoBlock>
      </div>

      <CommentModal
        open={openComments}
        onClose={handleOpenComments}
        description={description}
        internalComment={internalComment}
        publicComments={comments}
      />
    </>
  );
};
