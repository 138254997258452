import Grid from '@mui/material/Grid';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Dayjs } from 'dayjs';
import 'dayjs/locale/en-gb';
import { forwardRef, useState, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { getWarehouseLocale } from '@/helpers/unit';
import DatePicker from './DatePicker';

interface DateRangePickerProps {
  dateFrom: Dayjs | null;
  dateTo: Dayjs | null;
}

const DateRangePicker = forwardRef((props: DateRangePickerProps, ref) => {
  const { t } = useTranslation();

  const { dateFrom: defaultDateFrom, dateTo: defaultDateTo } = props;

  const [dateFrom, setDateFrom] = useState<Dayjs | null>(defaultDateFrom);
  const [dateTo, setDateTo] = useState<Dayjs | null>(defaultDateTo);

  const handleDateFromChange = (date: Dayjs | null) => {
    setDateFrom(date);
    if (date && dateTo && date > dateTo) {
      setDateTo(null);
    }
  };

  const handleDateToChange = (date: Dayjs | null) => {
    setDateTo(date);
  };

  useImperativeHandle(ref, () => ({
    getValue: () => {
      return { dateFrom: dateFrom?.toDate(), dateTo: dateTo?.toDate() };
    },
  }));

  const locale = getWarehouseLocale().toLowerCase();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      <Grid item xs={12} sm={6} md={3}>
        <DatePicker
          props={{
            label: t('QC_V2.QC_LST.DATE_FROM'),
            value: dateFrom,
            onChange: handleDateFromChange,
          }}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <DatePicker
          props={{
            label: t('QC_V2.QC_LST.DATE_TO'),
            value: dateTo,
            onChange: handleDateToChange,
            minDate: dateFrom as Dayjs | undefined,
          }}
          required
        />
      </Grid>
    </LocalizationProvider>
  );
});

DateRangePicker.displayName = 'DateRangePicker';

export default DateRangePicker;
