import { Stack, Typography } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormCheckbox from '@/components/Common/Form/FormCheckbox';
import { buildImageUrl, generateImgId } from '@/helpers/image';
import { useProduct } from '@/hooks/use-product';
import { PHOTO_TYPE } from '@/types/interfaces/defect-details';
import { QcInfoType } from '@/types/QcInfo';
import { SelectedPhotoType } from './PhotoForm';
import PhotoItem from './PhotoItem';
import { DynamicFormValues, PhotoFormType } from '../DefectDetails';

interface PDPPhotoFormProps {
  onClick: (data: SelectedPhotoType) => void;
  selectedPhoto?: SelectedPhotoType;
}

const PDPPhotoForm = ({ selectedPhoto, onClick }: PDPPhotoFormProps) => {
  const { t } = useTranslation();

  const { qcData } = useProduct();

  const {
    product: { images: pdpImages = [] },
  } = qcData as QcInfoType;

  const { control } = useFormContext<DynamicFormValues>();

  const { field: pdpField } = useController({ control, name: 'pdpPhotos' });

  const handleCheck = (id: number, imgName: string) => (checked: boolean) => {
    const photo: PhotoFormType = {
      id,
      checked,
      path: `${PHOTO_TYPE.PRODUIT}/${imgName}`,
      type: PHOTO_TYPE.PRODUIT,
    };

    // pdpPhotos is empty
    if (!pdpField.value) {
      pdpField.onChange([photo]);
      return;
    }

    let inputtedField = pdpField.value.find((v) => v.id === photo.id);

    // Checked photo is new
    if (!inputtedField) {
      pdpField.onChange([...pdpField.value, photo]);
      return;
    }

    inputtedField = Object.assign(inputtedField, photo);

    pdpField.onChange(pdpField.value);
  };

  const handleClick = (data: SelectedPhotoType) => () => {
    onClick(data);
  };

  return (
    <>
      <Typography variant="body1">
        {t('QC_V2.DEFECT.PDP_PHOTOS')?.toUpperCase()}
      </Typography>

      <Stack mb={1.5}>
        <FormCheckbox
          data-testid="defect_visible_onPhoto_checkbox"
          control={control}
          name="isAppearedInPhoto"
          label={t('QC_V2.DEFECT.VISIBLE_DEFECT')}
        />
      </Stack>

      <Stack sx={{ flexDirection: 'row', gap: 2, flexWrap: 'wrap' }}>
        {pdpImages.map((i, idx) => {
          const url = buildImageUrl(i);
          const htmlId = generateImgId(PHOTO_TYPE.PRODUIT, idx);
          const isClicked = htmlId === selectedPhoto?.htmlId;
          const isChecked = pdpField.value?.find((f) => f.id === idx)?.checked;

          return (
            <PhotoItem
              key={idx}
              name={`pdpPhotos.${idx}`}
              imgUrl={url}
              isClicked={isClicked}
              isChecked={isChecked}
              onChange={handleCheck(idx, i)}
              onClick={handleClick({
                htmlId,
                type: PHOTO_TYPE.PRODUIT,
                index: idx,
                path: `${PHOTO_TYPE.PRODUIT}/${i}`,
              })}
            />
          );
        })}
      </Stack>
    </>
  );
};

export default PDPPhotoForm;
