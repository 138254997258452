import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { getWarehouseConfig } from '@/helpers/unit';

dayjs.extend(utc);

export const formatUtcTime = (
  time: Date | null | undefined,
  format?: string
) => {
  const warehouseConfig = getWarehouseConfig();

  if (!time || !warehouseConfig) return null;

  const warehouseFormat = warehouseConfig.dateFormat;

  return dayjs(time).format(format || `${warehouseFormat} HH:mm:ss`);
};
