import { Typography } from '@mui/material';

export default function NegoModalContent() {
  return (
    <>
      <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
        The item is not fully complied to the description, but worth for
        <Typography
          component="span"
          sx={{ color: '#FFA000', paddingLeft: '0.4rem', fontWeight: '700' }}
        >
          Nego
        </Typography>
        !.
      </Typography>
      <Typography variant="body1">It will sort to</Typography>
      <Typography variant="h3">NEGO</Typography>
    </>
  );
}
