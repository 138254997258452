import { useQuery } from '@tanstack/react-query';

import { GET_QC_LIST } from '@/helpers/query-keys';
import { getQcList } from '@/services/qc.service';
import { QC_STATE_TYPE } from '@/types/QcStatus';

export interface QcListSearchParms {
  productId: string | null;
  agentEmail: string | null;
  qcStatus: QC_STATE_TYPE[];
  warehouseId: number | null;
  standbyReasons: number[] | null;
  dateFrom: Date | null;
  dateTo: Date | null;
  count: number;
}

export interface QcListHookParams extends QcListSearchParms {
  page: number | null;
  rowsPerPage: number | null;
}

export const useQcList = (params: QcListHookParams) => {
  const {
    page,
    productId,
    agentEmail,
    qcStatus,
    warehouseId,
    standbyReasons,
    rowsPerPage,
    dateFrom,
    dateTo,
    count,
  } = params;
  const { data, isLoading, isSuccess, refetch, isError } = useQuery({
    queryKey: [
      GET_QC_LIST,
      {
        page,
        productId,
        agentEmail,
        qcStatus,
        warehouseId,
        standbyReasons,
        rowsPerPage,
        dateFrom,
        dateTo,
        count,
      },
    ],
    queryFn: async () => {
      const data = await getQcList({
        page: (page || 0) + 1,
        'product.id': parseInt(productId || '0', 10) || null,
        'agent.email': agentEmail || null,
        state: qcStatus,
        'product.warehouse.id': warehouseId || null,
        'standbyReason.id': standbyReasons,
        itemsPerPage: rowsPerPage || 5,
        'order[createdAt]': 'desc',
        lastAgentActionDateFrom: dateFrom,
        lastAgentActionDateTo: dateTo,
      });

      return data;
    },
    enabled: !!dateFrom && !!dateTo,
  });

  return {
    qcList: data,
    isQcListLoading: isLoading,
    isQcListLoaded: isSuccess,
    refetchQcList: refetch,
    isFetchQcListError: isError,
  };
};
