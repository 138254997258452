import { useQuery } from '@tanstack/react-query';

import { GET_QC_STATES } from '@/helpers/query-keys';
import { getQcStates } from '@/services/qc.service';

export const useQcStates = () => {
  const { isLoading, isSuccess, data } = useQuery({
    queryKey: [GET_QC_STATES],
    queryFn: async () => {
      const data = await getQcStates();

      return data;
    },
    staleTime: Infinity,
  });

  return {
    qcStates: data,
    isQcStatesLoading: isLoading,
    isQcStatesLoaded: isSuccess,
  };
};
