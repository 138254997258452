import { useQuery } from '@tanstack/react-query';

import { GET_WAREHOUSES } from '@/helpers/query-keys';
import { getWarehouses } from '@/services/qc.service';

export const useWarehouses = () => {
  const { isLoading, isSuccess, data } = useQuery({
    queryKey: [GET_WAREHOUSES],
    queryFn: async () => {
      const data = await getWarehouses();

      return data;
    },
    staleTime: Infinity,
  });

  return {
    warehouses: data,
    isLoadingWarehouse: isLoading,
    isWarehouseLoaded: isSuccess,
  };
};
