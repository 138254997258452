import {
  Box,
  Drawer,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Collapse,
} from '@mui/material';
import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { RoutesMapping } from '@/helpers/routing';
import { DRAWER_WIDTH } from '@/layout/Layout';
import DrawerButton from './DrawerButton';

const getSideBarMenu = (t: TFunction<'translation', undefined>) => [
  {
    title: t('QC_V2.MENU.QUALITY_CONTROL'),
    path: RoutesMapping.HOME,
  },
  {
    title: t('QC_V2.MENU.QUALITY_CONTROL_LIST'),
    path: RoutesMapping.QC_LIST,
  },
  {
    title: t('QC_V2.MENU.AGENT_ACTIVITY'),
    path: RoutesMapping.AGENT_PRODUCTIVITY,
  },
];

interface SideMenuProps {
  isQCPage: boolean;
  open: boolean;
  onOpen: () => void;
}

export const SideMenu = ({ isQCPage, open, onOpen }: SideMenuProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const sideBarMenu = getSideBarMenu(t);

  const handleMenuItemClick = (index: number) => {
    navigate(sideBarMenu[index].path);
  };

  const drawerMinWidth = useMemo(() => {
    let width = 50;

    if (isQCPage) width = 0;

    return open ? undefined : width;
  }, [isQCPage, open]);

  return (
    <Box
      sx={{
        ...(!open && {
          borderRight: '1px solid rgba(224, 224, 224, 1)',
          width: isQCPage ? 0 : 60,
        }),
      }}
    >
      <Collapse
        in={open}
        collapsedSize={drawerMinWidth}
        orientation="horizontal"
      >
        <Drawer
          variant="persistent"
          open={open}
          sx={{
            width: DRAWER_WIDTH,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: DRAWER_WIDTH,
              boxSizing: 'border-box',
            },
          }}
        >
          <Toolbar />
          <Box sx={{ overflow: 'auto', position: 'relative' }}>
            <List>
              {sideBarMenu.map((content, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemButton
                    divider={index < sideBarMenu.length - 1}
                    selected={content.path === pathname}
                    onClick={() => handleMenuItemClick(index)}
                  >
                    <ListItemText primary={content.title} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>

          <DrawerButton
            onClick={onOpen}
            open={open}
            sx={{
              bottom: 10,
              right: 10,
              backgroundColor: (theme) => theme.palette.grey[200],
            }}
          />
        </Drawer>

        {!open && (
          <DrawerButton
            onClick={onOpen}
            open={open}
            sx={{
              bottom: 5,
              left: 5,
              background: 'white',
              '&:hover': {
                background: 'white',
              },
            }}
          />
        )}
      </Collapse>
    </Box>
  );
};
