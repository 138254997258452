import styled from '@emotion/styled';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import Fab from '@mui/material/Fab';

import { useTranslation } from 'react-i18next';
import { useModal } from '@/context/Modal.context';
import {
  useQualityControlTool,
  MODAL_TYPES,
} from '@/context/Qualitycontrol.context';
import { AdditionalItemType } from '@/types/AdditionalItem';

import AdditionalInfoSection from './AdditionalInfoSection';

const CustomizedFab = styled(Fab)`
  justify-content: flex-start;
  align-self: flex-start;
  margin-bottom: 0.5rem;
  box-shadow: none;
  padding: 0.25rem 0.5rem;
  height: auto;
  z-index: 1;
`;

export default function AdditionalItems({
  isViewMode,
}: {
  isViewMode: boolean;
}) {
  const { t } = useTranslation();

  const { additionalItems, setAdditionalItems } = useQualityControlTool();
  const { setModalInfo } = useModal();
  const handleDeleteItem = (item: AdditionalItemType) => {
    const updatedAdditionalItems = additionalItems.filter(
      (additionalItem) => additionalItem.id !== item.id
    );

    setAdditionalItems(updatedAdditionalItems);
  };

  const handleOpenAdditionalItems = () => {
    setModalInfo({
      modalType: MODAL_TYPES.ADDITIONAL_ITEMS,
      modalInfo: {},
    });
  };

  const renderContent = () => {
    return (
      <>
        {additionalItems.map((item: AdditionalItemType) => {
          return (
            <CustomizedFab
              key={item.id}
              variant="extended"
              size="small"
              color="default"
              disabled={isViewMode}
            >
              {item.name}
              <CloseIcon
                sx={{ mr: 1, ml: 1 }}
                onClick={() => handleDeleteItem(item)}
              />
            </CustomizedFab>
          );
        })}

        {!isViewMode && (
          <CustomizedFab
            variant="extended"
            size="small"
            color="default"
            aria-label="add"
            onClick={handleOpenAdditionalItems}
          >
            {t('QC_V2.MAIN.ADD_ADDITIONAL_ITEM')}
            <AddIcon sx={{ mr: 1, ml: 1 }} />
          </CustomizedFab>
        )}

        {isViewMode && !additionalItems.length && <Box sx={{ mt: -2 }}>-</Box>}
      </>
    );
  };

  return (
    <AdditionalInfoSection
      title={t('QC_V2.MAIN.ADDITIONAL_ITEMS')?.toUpperCase()}
      content={renderContent()}
    />
  );
}
