import { Button } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useModal } from '@/context/Modal.context';
import { useQcMode } from '@/hooks/use-qcMode';
import { CheckPointType } from '@/types/Checkpoint';

export default function DefectButton({
  checkPoint,
}: {
  checkPoint: CheckPointType;
}) {
  const { t } = useTranslation();

  const { setModalInfo } = useModal();
  const { isViewMode } = useQcMode();

  return (
    <Button
      data-testid="defect_button_add"
      disabled={isViewMode}
      onClick={() => {
        if (checkPoint.id) {
          setModalInfo({
            modalType: 'DEFECT_CREATION',
            modalInfo: {
              checkpointName: checkPoint.name,
              checkpointId: checkPoint.id,
            },
          });
        }
      }}
    >
      {`+${t('QC_V2.MAIN.ADD')}`}
    </Button>
  );
}
