import { useMutation } from '@tanstack/react-query';
import { UPDATE_QC_STATE } from '@/helpers/query-keys';
import { putQcState } from '@/services/qc.service';
import { CustomError } from '@/types/AxiosError';
import { UpdateQcState } from '@/types/QcInfo';

const useUpdateQcState = () => {
  /*
    TODO: @Jessica pls help to define the actual type for QC info response
    shouldn't use any
  */
  return useMutation<any, CustomError, UpdateQcState>({
    mutationKey: [UPDATE_QC_STATE],
    mutationFn: ({
      uuid,
      state,
      standbyReasonUuid,
      standbyComment,
      record,
    }: UpdateQcState) =>
      putQcState({ state, uuid, standbyReasonUuid, standbyComment, record }),
  });
};

export default useUpdateQcState;
