import { datadogRum } from '@datadog/browser-rum';
import { name, version } from '@/../package.json';
import { environments } from '@/environments';
import { getCookieObject } from '@/helpers/cookie';

const { config: datadogConfig } = environments.datadog;
const cookie = getCookieObject();

const noRum = cookie[environments.datadog.testCookie]
  ? (cookie[environments.datadog.testCookie] as string)
  : false;
if (
  environments.datadog?.enabled &&
  (!noRum || noRum === 'false' || noRum === '0')
) {
  datadogRum.init({
    applicationId: datadogConfig.applicationId,
    clientToken: datadogConfig.clientToken,
    site: 'datadoghq.eu',
    service: name,
    env: datadogConfig.environment,
    // Specify a version number to identify the deployed version of your application in Datadog
    version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
  });
}
